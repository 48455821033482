import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Responsive, Icon, Image } from 'semantic-ui-react';
import { BodyPadding, H2, Text, LargeButtonText } from './Styles';
// import ClipBoardTextIcon from '../../../assets/clipboard-text-outline.svg';
// import CommentQuestionIcon from '../../../assets/comment-question-outline.svg';

const Container = styled.div`
  width: 100%;
  display: grid;
  align-items: center;
  text-align: center;
  background-color: #39536c;
  color: #ffffff;
`;
const CustomButton = styled(Button)`
  width: 328px;
  height: 51px;
  padding: 10px 65px !important;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2) !important;
    color: white !important;
  }
`;

export default (props) => {
  const history = useHistory();
  const handleLearnMoreClick = () => {
    history.push('/about');
  };
  const handleRiskTestClick = () => {
    window.location.assign('https://www.cdc.gov/prediabetes/risktest/index.html');
  };

  return (
    <Container role="main" aria-label="Questions Container">
      <BodyPadding>
        <Grid columns={2} stackable divided inverted>
          <Grid.Row>
            <Grid.Column>
              <Icon.Group size="big">
                <Icon size="big" name="circle" />
                <Icon style={{ top: '45%', left: '48%' }}>
                  <Image
                    size="medium"
                    src="assets/comment-question-outline.svg"
                    alt="why path to prevention icon"
                    aria-hidden="true"></Image>
                </Icon>
              </Icon.Group>
              <H2>Why Path 2 Prevention?</H2>
              <br />
              <Text
                style={{
                  maxWidth: '500px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}>
                Path 2 Prevention is an online resource for people who may be at
                risk for developing type 2 diabetes. On Path 2 Prevention, you
                will find videos, games, and other personalized information to
                help you learn more about preventing type 2 diabetes in your
                life.
              </Text>
              <br />

              <Responsive
                {...Responsive.onlyMobile}
                as={CustomButton}
                onClick={handleLearnMoreClick}
                aria-label="Learn More About Path 2 Prevention"
                size="massive"
                inverted
                fluid>
                <LargeButtonText>Learn More</LargeButtonText>
              </Responsive>
            </Grid.Column>
            <Grid.Column>
              <Icon.Group size="big">
                <Icon size="big" name="circle" />
                <Icon style={{ top: '45%', left: '48%' }}>
                  <Image
                    size="medium"
                    src="assets/clipboard-text-outline.svg"
                    alt="am i at risk icon"
                    aria-hidden="true"></Image>
                </Icon>
              </Icon.Group>
              <H2>Am I at Risk?</H2>
              <br />
              <Text
                style={{
                  maxWidth: '500px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}>
                Unsure if you are at risk for prediabetes or type 2 diabetes? 1
                in 3 American adults have prediabetes and 90% of them don’t know
                it. Take the Prediabetes Risk Test to see where you stand and if
                Path 2 Prevention is right for you.
              </Text>
              <br />
              <Responsive
                {...Responsive.onlyMobile}
                as={CustomButton}
                onClick={handleRiskTestClick}
                size="massive"
                inverted
                fluid>
                <LargeButtonText>Take the Risk Test</LargeButtonText>
              </Responsive>
            </Grid.Column>
          </Grid.Row>
          <Responsive as={Grid.Row} minWidth={Responsive.onlyTablet.minWidth}>
            <Grid.Column>
              <CustomButton
                onClick={handleLearnMoreClick}
                aria-label="Learn More About Path 2 Prevention"
                size="massive"
                inverted>
                <LargeButtonText>Learn More</LargeButtonText>
              </CustomButton>
            </Grid.Column>
            <Grid.Column>
              <CustomButton
                onClick={handleRiskTestClick}
                size="massive"
                inverted>
                <LargeButtonText>Take the Risk Test</LargeButtonText>
              </CustomButton>
            </Grid.Column>
          </Responsive>
        </Grid>
      </BodyPadding>
    </Container>
  );
};
