import React, { useState } from 'react';
import styled from 'styled-components';
import { withStore } from '../../store';
import { H4, H5, SmallText, TealLink, Text, H1 } from './Styles';
import {
  Button,
  Label,
  Responsive,
  Image,
  Grid,
  Divider,
  Segment,
  Form,
} from 'semantic-ui-react';

const WhiteContainer = styled.div`
  max-width: 100%;
  background-color: #fff;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  @media only screen and (min-width: 768px) {
    max-width: 690px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 1140px;
  }
`;
const GrayContainer = styled.div`
  width: 100%;
  text-align: center;
  background-color: #f5f5f5;
  padding-bottom: 0px;
  @media only screen and (min-width: 768px) {
    padding-bottom: 50px;
  }
  @media only screen and (min-width: 992px) {
    padding-bottom: 50px;
  }
`;

const BlueContainer = styled.div`
  width: 100%;
  background-color: #f2f8fd;
  padding-top: 20px;
  padding-bottom: 20px;
`;

const GreenContainer = styled.div`
  width: 100%;
  background-color: rgba(54, 179, 126, 0.1);
  padding: 14px;
  @media only screen and (min-width: 768px) {
    width: 390px;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (min-width: 992px) {
    width: 100%;
  }
`;

const TitleContainer = styled.div`
  max-width: 290px;
  text-align: center;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-left: auto;
  margin-right: auto;
  @media only screen and (min-width: 768px) {
    max-width: 577px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 577px;
  }
`;
const TextContainer = styled.div`
  max-width: 341px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 18px;
  margin-top: 18px;
  @media only screen and (min-width: 768px) {
    max-width: 522px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 790px;
  }
`;
const FooterContainer = styled.div`
  max-width: 346px;
  text-align: center;
  padding-top: 19px;
  padding-bottom: 31px;
  margin-left: auto;
  margin-right: auto;

  @media only screen and (min-width: 768px) {
    max-width: 689px;
  }
  @media only screen and (min-width: 992px) {
    max-width: 100%;
  }
`;

const UnselectedButton = styled(Button)`
  border: 1px solid #979797 !important;
  background: #fff !important;
  margin-top: 12px !important;
  margin-bottom: 30px !important;
`;
const SelectedButton = styled(Button)`
  border: 1px solid #979797 !important;
  background-color: #39536c !important;
  color: white !important;
  margin-top: 12px !important;
  margin-bottom: 30px !important;
`;

const VerticalDivider = styled.span`
  display: inline-block;
  border-left: 1px solid #ccc;
  margin: 0 10px;
  height: 125px;
`;

const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  color: #fff !important;
  width: 315px;
  padding: 5px !important;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
  @media only screen and (min-width: 768px) {
    width: 261px;
  }
  @media only screen and (min-width: 992px) {
    width: 100%;
  }
`;

const TipText = styled.div`
  color: #333333;
  font-family: Nunito !important;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 19px;
`;

const ButtonText = styled.div`
  color: #ffffff;
  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  letter-spacing: 0.32px;
  line-height: 33px;
`;
const Game = (props) => {
  const [active, setActive] = useState('inperson');
  const [zipcode, setZipCode] = useState('');
  const [distance, setDistance] = useState(50);
  const [error, setError] = useState(false);
  const handleInPersonClick = () => {
    setActive('inperson');
  };
  const handleVirtualClick = () => {
    setActive('virtual');
  };

  const onChange = (e) => {
    const re = /^[0-9\b]+$/;
    if (
      (e.target.value === '' || re.test(e.target.value)) &&
      e.target.value <= 99999
    ) {
      setZipCode(e.target.value);
    }
  };

  const handleDropDownChange = (e) => {
    setDistance(e.target.value);
  };

  const handleFindClass = () => {
    if (zipcode.length < 5) {
      setError(true);
    } else {
      setError(false);
      window.open(
        `https://www.cdc.gov/diabetes-prevention/lifestyle-change-program/find-a-program.html`,
        '_blank'
      );
    }
  };

  const handleFindVirtualClass = () => {
    window.open(
      'https://www.cdc.gov/diabetes-prevention/lifestyle-change-program/find-a-program.html',
      '_blank'
    );
  };

  return (
    <GrayContainer>
      <WhiteContainer>
        <TitleContainer>
          <H1>National Diabetes Prevention Program Lifestyle Change Program</H1>
        </TitleContainer>
        <BlueContainer>
          <TextContainer>
            <H5>
              Find & Join a Lifestyle Change Program!
            </H5>
            <br />
            <SmallText>
              Continue to set yourself up for success! Join a Lifestyle Change Program and get a year of support from a coach and fellow participants. Explore in-person, online, distance learning, or combination programs using the buttons below. Once you find a program, please contact the provider for the most up to date information on enrollment.
            </SmallText>
            <br />
            <CustomButton onClick={handleFindVirtualClass} width="50%">
              <ButtonText>Browse programs</ButtonText>
            </CustomButton>
          </TextContainer>
        </BlueContainer>
        <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
          <TextContainer>
            <H5>Select a class type:</H5>
            <Button.Group widths="2">
              {active == 'inperson' ? (
                <SelectedButton onClick={handleInPersonClick}>
                  <H4 style={{ color: '#fff' }}>In-Person</H4>
                </SelectedButton>
              ) : (
                <UnselectedButton onClick={handleInPersonClick}>
                  <H4>In-Person</H4>
                </UnselectedButton>
              )}
              {active == 'virtual' ? (
                <SelectedButton onClick={handleVirtualClick}>
                  <H4 style={{ color: '#fff' }}>Virtual</H4>
                </SelectedButton>
              ) : (
                <UnselectedButton onClick={handleVirtualClick}>
                  <H4>Virtual</H4>
                </UnselectedButton>
              )}
            </Button.Group>
            {active == 'inperson' ? (
              <div>
                <SmallText style={{ paddingBottom: '19px' }}>
                  Find a program provider near you by searching with your ZIP
                  code. Please contact the program provider for the most up to
                  date information on all in-person class locations and program
                  start dates.
                </SmallText>
                <Image
                  src="assets/step5_in person.svg"
                  alt="in person"
                  size="small"
                  centered
                ></Image>
              </div>
            ) : (
              <div>
                <SmallText style={{ paddingBottom: '19px' }}>
                  Find a list of online and distance learning program providers
                  by clicking the button below. Please contact the program
                  provider for the most up to date information.
                </SmallText>
                <Image
                  src="assets/step5_online.svg"
                  size="small"
                  alt=""
                  centered
                ></Image>
              </div>
            )}
          </TextContainer>
          {active == 'inperson' ? (
            <TextContainer>
              <Form>
                <Form.Field>
                  <label htmlFor="zipcode">Zip Code:</label>
                  <input
                    id="zipcode"
                    onChange={onChange}
                    value={zipcode}
                  ></input>
                </Form.Field>

                <Form.Field>
                  <label htmlFor="distance">Distance:</label>
                  <select
                    id="distance"
                    onChange={handleDropDownChange}
                    value={distance}
                  >
                    <option value={5}>5 miles</option>
                    <option value={10}>10 miles</option>
                    <option value={20}>20 miles</option>
                    <option value={50}>50 miles</option>
                    <option value={100}>100 miles</option>
                  </select>
                </Form.Field>
              </Form>
            </TextContainer>
          ) : (
            <GreenContainer>
              <TextContainer>
                <H5>
                  <Image
                    src="assets/tip icon.svg"
                    alt="tip icon"
                    style={{
                      width: '17px',
                      height: '17px',
                      display: 'inline-block',
                      marginRight: '13px',
                    }}
                  ></Image>
                  Tip
                </H5>
                <br />
                <TipText>
                  Online classes are designed to be taken on your own schedule.
                  Participants log into course sessions using devices like
                  smartphones, laptops, or tablets. Distance learning classes
                  are also virtual but meet at a selected date and time via
                  remote classroom or telehealth methods (for example,
                  conference calls or Zoom).
                </TipText>
              </TextContainer>
            </GreenContainer>
          )}
          <TextContainer>
            <Grid columns={2} stackable style={{ border: 'none !important' }}>
              <Grid.Row>
                {active == 'inperson' ? (
                  <Grid.Column textAlign="center">
                    <CustomButton onClick={handleFindClass} compact>
                      <ButtonText>Find a Class Near Me</ButtonText>
                    </CustomButton>
                    {error ? (
                      <TipText style={{ color: '#BF2600' }}>
                        Please enter a valid ZIP Code of 5 numbers.
                      </TipText>
                    ) : (
                      ''
                    )}
                  </Grid.Column>
                ) : (
                  <Grid.Column textAlign="center">
                    <CustomButton onClick={handleFindVirtualClass} compact>
                      <ButtonText>Find a Virtual Class</ButtonText>
                    </CustomButton>
                  </Grid.Column>
                )}
                <Grid.Column textAlign="center">
                  <Text>
                    <TealLink
                      href="https://www.cdc.gov/diabetes-prevention/index.html"
                      target="_blank"
                    >
                      Learn More About the National DPP
                    </TealLink>
                  </Text>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </TextContainer>
        </Responsive>
        <Responsive minWidth={Responsive.onlyComputer.minWidth}>
          <TextContainer>
            <H1>What types of programs are available?</H1>
            <Grid columns={2} stackable relaxed>
              <Grid.Row>
                <Grid.Column>
                  <div style={{borderLeft: '3px solid #8cc4ce', paddingLeft: '6px'}}>
                    <H5>In-Person Programs</H5>
                    <SmallText
                      style={{ paddingBottom: '19px', display: "flex", alignItems: 'center' }}
                    >
                      Connect with others in person! Find in-person classes near you. test2
                      <Image
                      src="assets/step5_in person.svg"
                      alt="in person"
                      style={{ height: '81px', width: '101px' }}
                    ></Image>
                    </SmallText>
                  </div>
                  <br />
                  <div style={{borderLeft: '3px solid #8cc4ce', paddingLeft: '6px'}}>
                    <H5>Distance Learning Programs</H5>
                    <SmallText
                      style={{ paddingBottom: '19px', display: "flex", alignItems: 'center' }}
                    >
                      Self study from a distance with support as you need it.
                      <Image
                      src="assets/step5_distancelearning2.svg"
                      alt="distance learning"
                      style={{ height: '81px', width: '101px' }}
                    ></Image>
                    </SmallText>
                  </div>
                </Grid.Column>
                <Grid.Column>
                  <div style={{borderLeft: '3px solid #8cc4ce', paddingLeft: '6px'}}>
                    <H5>Online Programs</H5>
                    <SmallText
                      style={{ paddingBottom: '19px', display: "flex", alignItems: 'center' }}
                    >
                      Connect online with others & get support from wherever you are.
                      <Image
                      src="assets/step5_online.svg"
                      alt="connect online"
                      style={{ height: '81px', width: '101px' }}
                    ></Image>
                    </SmallText>
                  </div>
                  <br />
                  <div style={{borderLeft: '3px solid #8cc4ce', paddingLeft: '6px'}}>
                    <H5>Combination Programs</H5>
                    <SmallText
                      style={{ paddingBottom: '19px', display: "flex", alignItems: 'center' }}
                    >
                      Find programs that blend in-person, online, and self study.
                      <Image
                      src="assets/step5_combinationprograms.svg"
                      alt="combination programs"
                      style={{ height: '81px', width: '101px' }}
                    ></Image>
                    </SmallText>
                  </div>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </TextContainer>
          <br />
          <Text>
            <TealLink
              href="https://www.cdc.gov/diabetes-prevention/index.html"
              target="_blank"
            >
              Learn More About the National DPP
            </TealLink>
          </Text>
        </Responsive>

        <br />
        <div
          style={{
            borderTop: '2px solid #E0E0E0',
            textAlign: 'center',
          }}
        >
          <FooterContainer>
            <TipText style={{ color: '#333333 !important' }}>
              Can't find a class that works for you? The registry lists all
              available program providers.
            </TipText>
            <Text>
              <TealLink
                href="https://www.cdc.gov/diabetes-prevention/lifestyle-change-program/find-a-program.html"
                target="_blank"
              >
                View the Full List of Program Providers
              </TealLink>
            </Text>
          </FooterContainer>
        </div>
      </WhiteContainer>
    </GrayContainer>
  );
};

export default withStore(Game);
