import React, {useState} from 'react';
import styled from 'styled-components';
// import CDCLogo from '../../../assets/cdclogo.svg';
// import CDCLogoSmall from '../../../assets/cdclogosmall.svg';
import { Image, Responsive, Icon, Sticky } from 'semantic-ui-react';
import { Padding } from './Styles';

const Container = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
color: #000;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
box-sizing: border-box;
width: 100%;
padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;
max-width: 1140px;
`;

const Header = styled.header`
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: var(--defaults-fontsize);
  box-sizing: border-box;
  display: block;`;

const HeaderWrapper = styled.div`
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  font-size: var(--defaults-fontsize);
  box-sizing: border-box;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  background: linear-gradient(90deg, #0057B7 0%, #0081a1 100%);
  padding: var(--defaults-spacing);
  height: fit-content !important;`

const Row = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
color: #000;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
box-sizing: border-box;
display: flex;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;
padding-top: 0.5rem !important;
padding-bottom: 0.5rem !important;`;

const Col = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
color: #000;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
box-sizing: border-box;
position: relative;
width: 100%;
padding-right: 15px;
padding-left: 15px;
flex-basis: 0;
flex-grow: 1;
min-width: 0;
max-width: 100%;
display: flex;
align-items: center;`

const CdcLogoImg = styled.a`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
box-sizing: border-box;
background-color: transparent;
color: #075290;
text-decoration: underline;
display: inline-block;
min-width: unset;
margin-right: 2rem;`

const SrOnly = styled.span`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
color: #075290;
box-sizing: border-box;
text-decoration: none !important;
position: absolute;
width: 1px;
height: 1px;
padding: 0;
margin: -1px;
overflow: hidden;
clip: rect(0, 0, 0, 0);
white-space: nowrap;
border: 0;`;

const CdcLogoDesktop = styled.img`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
color: #075290;
box-sizing: border-box;
vertical-align: middle;
border-style: none;
max-width: 125px;
min-width: 75px;
width: 100%;
display: block;`;

const CdcLogoMobile = styled.img`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
color: #075290;
box-sizing: border-box;
vertical-align: middle;
border-style: none;
max-width: 125px;
min-width: 75px;
width: 100%;
display: none;`;

const CdcLogoTitle = styled.a`
font-weight: 400;
text-align: left;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
box-sizing: border-box;
background-color: transparent;
min-width: unset;
color: #fff;
display: inline-block;
text-decoration: none;
font-size: 2rem;
font-family: "Poppins", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
line-height: 1;`;

const TpSearch = styled.div`
font-weight: 400;
line-height: 1.5;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
color: #000;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
box-sizing: border-box;
position: relative;
width: 100%;
padding-right: 15px;
padding-left: 15px;
flex: 0 0 41.666667%;
max-width: 41.666667%;
text-align: right;
align-items: flex-end;
display: flex;
justify-content: flex-end;`;

const HeaderSearch = styled.div`
font-weight: 400;
line-height: 1.5;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
color: #000;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
text-align: right;
box-sizing: border-box;
margin-top: inherit;`;

const CdcHeaderSearchForm = styled.form`
font-weight: 400;
line-height: 1.5;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
color: #000;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
text-align: right;
box-sizing: border-box;`;

const FallbackMobileSearch = styled.button`
box-sizing: border-box;
margin: 0;
font-family: inherit;
overflow: visible;
text-transform: none;
-webkit-appearance: button;
font-weight: 400;
text-align: center;
vertical-align: middle;
user-select: none;
border: 1px solid transparent;
font-size: 1rem;
line-height: 1.5;
border-radius: 0.25rem;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
white-space: nowrap;
display: none !important;
text-decoration: none;
background-color: #005eaa;
border-color: #005eaa;
color: #fff;
cursor: pointer;
padding: 0.375rem 0.175rem 0.375rem 0.75rem;
margin-top: 0;`;

const FallbackSpan = styled.span`
font-family: inherit;
text-transform: none;
font-weight: 400;
text-align: center;
user-select: none;
font-size: 1rem;
line-height: 1.5;
white-space: nowrap;
color: #fff;
cursor: pointer;
box-sizing: border-box;
display: inline-block;`;

const FallbackI1 = styled.i`
font-family: inherit;
text-transform: none;
font-weight: 400;
text-align: center;
user-select: none;
font-size: 1rem;
white-space: nowrap;
color: #fff;
cursor: pointer;
box-sizing: border-box;
position: relative;
display: inline-block;
vertical-align: middle;
line-height: 1 !important;`;

const FallbackI2 = styled.i`
font-family: inherit;
text-transform: none;
font-weight: 400;
text-align: center;
user-select: none;
font-size: 1rem;
white-space: nowrap;
color: #fff;
cursor: pointer;
box-sizing: border-box;
position: relative;
display: none !important;
vertical-align: middle;
line-height: 1 !important;`;

const DropdownMenuSearch = styled.div`
box-sizing: border-box;
float: left;
min-width: 10rem;
margin: 0.125rem 0 0;
font-size: 1rem;
color: #212529;
list-style: none;
background-clip: padding-box;
border-radius: 0.25rem;
right: 0;
max-width: calc(100vw - 25px);
text-align: right;
z-index: 1005;
position: relative !important;
top: inherit;
left: inherit;
transform: none !important;
display: flex;
border: 0;
box-shadow: none;
padding: 0;
width: 100%;
justify-content: flex-end;
background-color: transparent;`;

const InputGroup = styled.div`
font-size: 1rem;
color: #212529;
list-style: none;
text-align: right;
box-sizing: border-box;
position: relative;
display: flex;
flex-wrap: wrap;
align-items: stretch;
width: 100%;
justify-content: flex-end;
max-width: 350px;
margin-top: 0;`;

const SearchInput = styled.input`
list-style: none;
box-sizing: border-box;
margin: 0;
font-family: inherit;
overflow: visible;
display: block;
height: calc(1.5em + 0.75rem + 2px);
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
background-clip: padding-box;
transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
position: relative;
flex: 1 1 auto;
width: 1%;
min-width: 0;
margin-bottom: 0;
background-color: transparent;
border: none;
border-radius: 0;
border-bottom: 1px solid #fff;
color: #fff;
padding: 1.25rem;
padding-left: 0.8rem;
&::focus {
  outline: none !important;
}
&::placeholder {
  color: #fff !important;
}`;

const FormControlClear = styled.span`
font-weight: 400;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
list-style: none;
box-sizing: border-box;
vertical-align: middle;
pointer-events: auto;
cursor: pointer;
width: 20px;
height: 20px;
position: absolute;
top: 0;
right: 59px;
z-index: 99;
display: block;
text-align: center;
color: #fff;
font-size: 1.4rem;
line-height: 1.5;
visibility: hidden;`;

const InputGroupAppend = styled.div`
font-weight: 400;
line-height: 1.5;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: 1rem;
color: #212529;
list-style: none;
text-align: right;
box-sizing: border-box;
display: flex;
margin-left: -1px;`;

const SearchSubmit = styled.button`
list-style: none;
box-sizing: border-box;
margin: 0;
font-family: inherit;
overflow: visible;
text-transform: none;
-webkit-appearance: button;
display: inline-block;
font-weight: 400;
text-align: center;
vertical-align: middle;
user-select: none;
padding: 0.375rem 0.75rem;
font-size: 1rem;
line-height: 1.5;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
text-decoration: none;
position: relative;
z-index: 2;
padding-top: 0;
padding-bottom: 2px;
cursor: pointer;
background-color: transparent;
border: none;
border-bottom: 1px solid var(--white);
border-radius: 0;
color: var(--white);`;

const SearchSubmitSpan = styled.span`
list-style: none;
font-family: inherit;
text-transform: none;
font-weight: 400;
text-align: center;
user-select: none;
font-size: 1rem;
line-height: 1.5;
cursor: pointer;
color: var(--white);
box-sizing: border-box;
position: absolute;
width: 1px;
height: 1px;
padding: 0;
margin: -1px;
overflow: hidden;
clip: rect(0, 0, 0, 0);
white-space: nowrap;
border: 0;`;

const IconMagnify = styled.i`
list-style: none;
font-family: inherit;
text-transform: none;
font-weight: 400;
text-align: center;
user-select: none;
font-size: 1rem;
cursor: pointer;
box-sizing: border-box;
position: relative;
vertical-align: middle;
padding-bottom: 2px;
line-height: 0.9em;
background-color: transparent;
border: none;
border-radius: 0;
padding-top: 2px;
display: block;
border-bottom: none;
color: var(--white);`;

const HiddenInput = styled.input`
box-sizing: border-box;
margin: 0;
font-family: inherit;
font-size: inherit;
line-height: inherit;
overflow: visible;`;

const CdcMobileSiteTitle = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
box-sizing: border-box;
background-color: var(--colors-blue-vivid-70);
color: #fff;
padding: 1rem;
width: calc(100% + 30px);
margin: -21px -15px 0 -15px;
display: none;`;

const OfficialWebsite = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
box-sizing: border-box;
width: 100%;
padding-right: 15px;
padding-left: 15px;
margin-right: auto;
margin-left: auto;
max-width: 1140px;
padding-bottom: 12px;
padding-top: 12px;
font-family: "Poppins", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
color: var(--colors-gray-cool-60);`;

const OfficialWebsiteHeader = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-family: "Poppins", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
color: var(--colors-gray-cool-60);
box-sizing: border-box;
display: flex !important;
font-size: 12px;
justify-content: flex-end;
background: #fff`;

const OfficialWebsiteBody = styled.div`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    color: var(--colors-gray-cool-60);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    font-size: 16px;
    padding-top: 1.5rem;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    max-width: 1300px;
    margin: 0 auto;`

const OfficialWebsiteFigure = styled.figure`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    color: var(--colors-gray-cool-60);
    font-size: 16px;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    box-sizing: border-box;
    margin: 0 0 1rem;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 50%;
    max-width: 50%;
    display: flex !important;`

const OfficialWebsiteSmall = styled.small`
text-align: left;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-family: "Poppins", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
color: var(--colors-gray-cool-60);
box-sizing: border-box;
font-weight: 400;
line-height: 1.15;
margin-left: 0.5rem !important;
font-size: inherit;`;

const OfficialWebsiteButton = styled.button`
box-sizing: border-box;
border-radius: 0;
margin: 0;
font-family: inherit;
line-height: inherit;
overflow: visible;
text-transform: none;
-webkit-appearance: button;
cursor: pointer;
color: var(--colors-primary-link);
background-color: transparent;
border: none;
padding: 0;
font-size: inherit;`;

const FlagImage = styled.img`
font-weight: 400;
line-height: 1.5;
text-align: left;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-family: "Poppins", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
color: var(--colors-gray-cool-60);
font-size: 12px;
width: 16px;
aspect-ratio: auto 16 / 11;
height: 11px;
box-sizing: border-box;
vertical-align: middle;
border-style: none;`;

const OfficialWebsiteImg = styled.img`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    color: var(--colors-gray-cool-60);
    font-size: 16px;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    box-sizing: border-box;
    vertical-align: middle;
    border-style: none;
    height: 30px;
    width: 30px;`

  const OfficialWebsiteFigCaption = styled.figcaption`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    color: var(--colors-gray-cool-60);
    font-size: 16px;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    box-sizing: border-box;
    display: block;
    margin-left: 1rem !important;
    padding-right: 15%;`

const MobileHeader = styled.header`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: var(--defaults-fontsize);
    box-sizing: border-box;
    display: block;`

const MobileWrapper = styled.div`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: var(--defaults-fontsize);
    box-sizing: border-box;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    background-image: url('https://oadc-dmb-dev.cdc.gov/nfou.png');
    background-size: 200px;
    background-color: transparent;`

const MobileContainer = styled.div`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: var(--defaults-fontsize);
    box-sizing: border-box;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: none;
    min-height: 90px;`

const MobileRow = styled.div`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: var(--defaults-fontsize);
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;`

const MobileLogo = styled.div`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: var(--defaults-fontsize);
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding-right: 15px;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
    padding-left: 0;
    align-items: center;
    display: flex;
    max-height: 50px;
    margin-top: 5px;
    margin-left: -1px;`

const MobileLogoA = styled.a`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: var(--defaults-fontsize);
    box-sizing: border-box;
    background-color: transparent;
    color: #075290;
    text-decoration: underline;
    display: inline-block;
    margin-right: 2rem;
    min-width: 75px;
    width: 100%;
    max-width: 75px;`

const MobileCol2 = styled.div`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: var(--defaults-fontsize);
    box-sizing: border-box;
    position: relative;
    width: 100%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
    text-align: right;
    padding: 0 0 0.75rem 0;`

const MobileSearch = styled.div`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: var(--defaults-fontsize);
    text-align: right;
    box-sizing: border-box;
    margin-top: 0.2rem;`

const MobileSearchForm = styled.form`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: var(--defaults-fontsize);
    text-align: right;
    box-sizing: border-box;`

const MobileSearchButton = styled.button`
    box-sizing: border-box;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    white-space: nowrap;
    cursor: pointer;
    padding: 0;
    color: #005eaa;
    background: transparent !important;
    text-decoration: none !important;
    border-color: transparent !important;
    margin-top: 0;`

const MobileSearchInput = styled.div`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    float: left;
    min-width: 10rem;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    width: 500px;
    padding: 20px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.23);
    max-width: calc(100vw - 25px);
    text-align: right;
    right: auto;
    bottom: auto;
    display: block;
    z-index: 1005;
    background-color: #fff;
    position: absolute;
    transform: translate3d(-292px, 45px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;`

const MobileSearchInputGroup = styled.div`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 1rem;
    color: #212529;
    list-style: none;
    text-align: right;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    justify-content: flex-end;
    margin-top: 0;`

const MobileInput = styled.input`
    list-style: none;
    box-sizing: border-box;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    display: block;
    height: calc(1.5em + 0.75rem + 2px);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #fff;
    color: var(--colors-blue-vivid-70);
    padding: 1.25rem;
    padding-left: 0.8rem;`

const MobileTitle = styled.div`
    --colors-blue-vivid-70: #0b4778;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: var(--defaults-fontsize);
    box-sizing: border-box;
    background-color: var(--colors-blue-vivid-70);
    color: #fff;
    padding: 1rem;
    width: calc(100% + 30px);
    margin: -21px -15px 0 -15px;`

const MobileA = styled.a`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 18px;
    box-sizing: border-box;
    background-color: transparent;
    color: #fff;
    text-decoration: none;`

const TabletSearchButton = styled.button`
    box-sizing: border-box;
    margin: 0;
    font-family: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    white-space: nowrap;
    border-color: #005eaa;
    color: #fff;
    cursor: pointer;
    background-color: #005091;
    padding: 0.375rem 0.175rem 0.375rem 0.75rem;
    text-decoration: none !important;
    margin-top: 0;`

const TabletSearchInput = styled.div`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    float: left;
    min-width: 10rem;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    list-style: none;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    width: 500px;
    padding: 20px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.23);
    max-width: calc(100vw - 25px);
    text-align: right;
    right: auto;
    bottom: auto;
    display: block;
    z-index: 1005;
    background-color: #fff;
    position: absolute;
    transform: translate3d(-302px, 44px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;`

export default (props) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const closeStyle = {
    visibility: searchValue.length > 0 ? "visible" : "hidden",
  }

  const clearSearch = () => {
    setSearchValue('');
  }

  const [showOfficial, setShowOfficial] = useState(false);

  const handleShowOfficialChange = (event) => {
    setShowOfficial(!showOfficial);
  }

  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);

  const handleMobileSearchOpen = (event) => {
    setMobileSearchOpen(!mobileSearchOpen);
  }

  return (
    <>
    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
    <div style={{background: "#fff"}}>
    <OfficialWebsite className="cdc-header-official-notice container noindex" style={{background: "#fff", width: "72vw"}}>
      <OfficialWebsiteHeader className="cdc-header-official-notice-header d-flex">
        <FlagImage src="assets/us_flag_small.png" width="16" height="16" alt="U.S. Flag" />
        <OfficialWebsiteSmall className="ml-2">
          An official website of the United States Government &nbsp;
          <OfficialWebsiteButton className="cdc-header-official-notice-header-btn" style={{color: "#005EA2"}} onClick={handleShowOfficialChange}>
            Here's how you know &#709;
          </OfficialWebsiteButton>
        </OfficialWebsiteSmall>
      </OfficialWebsiteHeader>
      {showOfficial && (
        <OfficialWebsiteBody className="cdc-header-official-notice-body row">
          <OfficialWebsiteFigure className="col-lg-6 d-flex">
            <OfficialWebsiteImg className="cdc-header-official-notice-body-img" src="assets/icon-dot-gov.svg" alt="Official Icon" aria-hidden="true" />
            <OfficialWebsiteFigCaption className="cdc-header-official-notice-body-info ml-3">
              <p className="cdc-header-official-notice-body-title mb-1">
                <strong>Offical websites use .gov</strong>
              </p>
              <p>
                A .gov website belongs to an official government organization in the United States.
              </p>
            </OfficialWebsiteFigCaption>
          </OfficialWebsiteFigure>
          <OfficialWebsiteFigure className="col-lg-6 d-flex">
            <OfficialWebsiteImg className="cdc-header-official-notice-body-img" src="assets/icon-https.svg" alt="Lock icon" aria-hidden="true" />
            <OfficialWebsiteFigCaption className="cdc-header-official-notice-body-info ml-3">
              <p className="cdc-header-official-notice-body-title mb-1">
                <strong>Secure .gov websites use HTTPS</strong>
              </p>
              <p>
              A lock (<span role="img" aria-label="lock">🔒</span>) or https:// means you've safely connected to the .gov website. Share sensitive information only on official, secure websites.
              </p>
            </OfficialWebsiteFigCaption>
          </OfficialWebsiteFigure>
        </OfficialWebsiteBody>
      )}
    </OfficialWebsite>
    <Header id="page_banner" role="banner" aria-label="banner">
      <HeaderWrapper className="container-fluid header-wrapper">
        <Container className="container">
          <Row className="row py-2">
            <Col className="col cdc-logo">
              <CdcLogoImg href="https://www.cdc.gov">
                <SrOnly> Centers for Disease Control and Prevention. CDC twenty four seven. Saving Lives, Protecting People</SrOnly>
                <CdcLogoDesktop className="cdc-logo--desktop" src="assets/logo-notext.svg" alt="CDC Logo" />
                <CdcLogoMobile className="cdc-logo--mobile" src="assets/cdc-logo-tag-right-alt.svg" alt="CDC Logo" />
              </CdcLogoImg>
              <CdcLogoTitle className="cdc-logo__title" href="#">Path2Prevention</CdcLogoTitle>
            </Col>
            <TpSearch className="col-2 col-md-3 col-xl-5 col-xxl-4 tp-search">
              <HeaderSearch className="headerSearch cdc-header-search">
                <CdcHeaderSearchForm className="cdc-header-search-form" accept-charset="UTF-8" action="https://search.cdc.gov/search" method="get" autocomplete="off">
                  <FallbackMobileSearch className="btn btn-primary dropdown-toggle no-toggle search-button ssi d-xl-none" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="search">
                    <FallbackSpan>Search</FallbackSpan>
                    <FallbackI1 className="fi cdc-icon-magnify dropdown-menu-medium-search-icon x24" aria-hidden="true" />
                    <FallbackI2 className="fi cdc-icon-close dropwdown-menu-medium-close-icon x24" aria-hidden="true" />
                  </FallbackMobileSearch>
                  <DropdownMenuSearch className="dropdown-menu dropdown-menu-right dropdown-menu-search dropdown-menu-medium-search">
                    <InputGroup>
                      <SearchInput id="headerSearch" className="form-control" type="text" maxlength="300" placeholder="Search" aria-label="Search" name="query" value={searchValue} onChange={handleSearchChange}/>
                      <FormControlClear className="form-control-clear cdc-icon-close" style={closeStyle} onClick={clearSearch}>
                        <Icon style={{color: "white !important"}}>
                          <img src="assets/close.svg" alt="Clear Search" style={{filter: "invert(100%)"}}/>
                        </Icon>
                      </FormControlClear>
                      <InputGroupAppend className="input-group-append">
                        <SearchSubmit className="btn search-submit" type="submit">
                          <SearchSubmitSpan className="sr-only">Submit</SearchSubmitSpan>
                          <Icon style={{color: "white !important"}}>
                            <img src="assets/magnifying.svg" alt="Search" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SearchSubmit>
                      </InputGroupAppend>
                    </InputGroup>
                  </DropdownMenuSearch>
                  <HiddenInput type="hidden" name="affiliate" value="cdc-main" />
                  <HiddenInput type="hidden" name="sitelimit" value="" />
                </CdcHeaderSearchForm>
              </HeaderSearch>
            </TpSearch>
          </Row>
        </Container>
        <CdcMobileSiteTitle className="cdc-mobile-site-title">
          <a href="#">Path2Prevention</a>
        </CdcMobileSiteTitle>
      </HeaderWrapper>
    </Header>
    </div>
    </Responsive>
    <Responsive {...Responsive.onlyTablet}>
      <MobileHeader id="page_banner" role="banner" aria-label="Banner">
        <MobileWrapper className="container-fluid header-wrapper">
          <MobileContainer className="container">
            <MobileRow className="row py-2">
              <MobileLogo className="col cdc-logo">
                <MobileLogoA href="https://www.cdc.gov" className="cdc-logo__img">
                  <img src="assets/logo-notext.svg" alt="CDC"/>
                </MobileLogoA>
              </MobileLogo>
              <MobileCol2 className="col-2 col-md-3 col-xl-5 col-xxl-4 tp-search">
                <MobileSearch className="headerSearch cdc-header-search">
                  <MobileSearchForm acceptCharset="UTF-8" action="https://search.cdc.gov/search/" className="cdc-header-search-form" method="get" autocomplete="off">
                    <TabletSearchButton className="btn btn-primary dropdown-toggle no-toggle search-button ssi d-xl-none" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="Search" onClick={() => handleMobileSearchOpen()}>
                      Search <img width="18px" height="18px" src={mobileSearchOpen ? "assets/close.svg" : "assets/magnifying.svg"} style={{filter: "invert(100%)"}} alt="CDC"/>
                    </TabletSearchButton>
                    {mobileSearchOpen && (
                      <TabletSearchInput className="dropdown-menu dropdown-menu-right dropdown-menu-search dropdown-menu-medium-search" x-placement="bottom-end">
                        <MobileSearchInputGroup className="input-group">
                          <MobileInput id="headerSearch" type="text" className="form-control" maxlength="300" placeholder="Search" aria-label="Search" name="query" />
                        </MobileSearchInputGroup>
                      </TabletSearchInput>)}
                  </MobileSearchForm>
                </MobileSearch>
              </MobileCol2>
            </MobileRow>
          </MobileContainer>
          <MobileTitle className="cdc-mobile-site-title">
            <MobileA href="#">Path2Prevention</MobileA>
          </MobileTitle>
        </MobileWrapper>
      </MobileHeader>
    </Responsive>
    <Responsive {...Responsive.onlyMobile}>
      <MobileHeader id="page_banner" role="banner" aria-label="Banner">
        <MobileWrapper className="container-fluid header-wrapper">
          <MobileContainer className="container">
            <MobileRow className="row py-2">
              <MobileLogo className="col cdc-logo">
                <MobileLogoA href="https://www.cdc.gov" className="cdc-logo__img">
                  <img src="assets/logo-notext.svg" alt="CDC"/>
                </MobileLogoA>
              </MobileLogo>
              <MobileCol2 className="col-2 col-md-3 col-xl-5 col-xxl-4 tp-search">
                <MobileSearch className="headerSearch cdc-header-search">
                  <MobileSearchForm acceptCharset="UTF-8" action="https://search.cdc.gov/search/" className="cdc-header-search-form" method="get" autocomplete="off">
                    <MobileSearchButton className="btn btn-primary dropdown-toggle no-toggle search-button ssi d-xl-none" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="Search" onClick={() => handleMobileSearchOpen()}>
                      <img width="30px" height="30px" src={mobileSearchOpen ? "assets/close.svg" : "assets/magnifying.svg"} alt="CDC"/>
                    </MobileSearchButton>
                    {mobileSearchOpen && (
                      <MobileSearchInput className="dropdown-menu dropdown-menu-right dropdown-menu-search dropdown-menu-medium-search" x-placement="bottom-end">
                        <MobileSearchInputGroup className="input-group">
                          <MobileInput id="headerSearch" type="text" className="form-control" maxlength="300" placeholder="Search" aria-label="Search" name="query" />
                        </MobileSearchInputGroup>
                      </MobileSearchInput>)}
                  </MobileSearchForm>
                </MobileSearch>
              </MobileCol2>
            </MobileRow>
          </MobileContainer>
          <MobileTitle className="cdc-mobile-site-title">
            <MobileA href="#">Path2Prevention</MobileA>
          </MobileTitle>
        </MobileWrapper>
      </MobileHeader>
    </Responsive>
    </>
  );
};
