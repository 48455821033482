import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Button, Label } from 'semantic-ui-react';

export const H1 = styled.h1`
  color: #000000;
  font-family: Poppins !important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 20px;
  @media only screen and (min-width: 768px) {
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 29px;
  }
  @media only screen and (min-width: 992px) {
    ont-size: 22px;
    letter-spacing: 0.32px;
    line-height: 29px;
  }
`;
export const H4 = styled.div`
  color: #000000;
  font-family: Poppins !important;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.26px;
  line-height: 20px;
  @media only screen and (min-width: 768px) {
    font-size: 22px;
    letter-spacing: 0.32px;
    line-height: 29px;
  }
  @media only screen and (min-width: 992px) {
    ont-size: 22px;
    letter-spacing: 0.32px;
    line-height: 29px;
  }
`;

export const H5 = styled.div`
  color: #262525;
  font-family: Nunito !important;
  font-size: 16px;
  font-weight: bolder;
  letter-spacing: 0.23px;
  line-height: 22px;
  margin: 2px;
  @media only screen and (min-width: 768px) {
    font-size: 16px;
    letter-spacing: 0.23px;
    line-height: 22px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
    letter-spacing: 0.23px;
    line-height: 22px;
  }
`;

export const SmallText = styled.div`
  color: #222a35 !important;
  font-family: Nunito !important;
  font-size: 14px;
  letter-spacing: 0.2;
  line-height: 19px;
  @media only screen and (min-width: 768px) {
    font-size: 14px;
    letter-spacing: 0.2;
    line-height: 19px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 16px;
    letter-spacing: 0.2;
    line-height: 22px;
  }
`;

export const Text = styled.div`
  color: #222a35 !important;
  font-family: Nunito !important;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.26px;
  line-height: 24px;
  @media only screen and (min-width: 768px) {
    font-size: 18px;
    letter-spacing: 0.26px;
    line-height: 24px;
  }
  @media only screen and (min-width: 992px) {
    font-size: 18px;
    letter-spacing: 0.26px;
    line-height: 24px;
  }
`;

export const TealLink = styled.a`
  color: #007c91 !important;
  text-decoration: underline;
  &:hover {
    text-decoration: none !important;
  }
`;

export const ColorBorder = styled.a`
  border-left: 5px solid #3498db;
`;