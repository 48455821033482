import React, { useState } from 'react';
import styled from 'styled-components';
import { withStore } from '../../store';
import { Responsive, Button, Image, Icon, Grid } from 'semantic-ui-react';
import {
  GrayContainer,
  TextContainer,
  WhiteContainer,
  H5,
  TipText,
  BlueTextContainer,
  Container,
  PageTitle,
  PageNumber,
  SuccessTitle,
  Text,
  ExpandingTile,
  Chevron,
  AP3Line,
  ChevronUp,
  AP3Text,
  SuccessMessage,
  SucessTextBox,
  SuccessTip,
} from './Styles';
import AddendumQuestions from '../AddendumQuestions';

const IconAP3 = styled(Icon)`
  padding-top: 7px !important;
  top: 50% !important;
  left: 40% !important;
  @media only screen and (min-width: 768px) {
    left: 48% !important;
  }
  @media only screen and (min-width: 992px) {
    left: 48% !important;
  }
`;
const IconCircle = styled(Icon)`
  font-size: 25px !important;
  color: #f5f5f5;
  @media only screen and (min-width: 768px) {
    font-size: 30px !important;
  }
  @media only screen and (min-width: 992px) {
  }
`;
const Action3Input = styled.input`
  height: 14.6px !important;
  width: 14.6px !important;
  @media only screen and (min-width: 768px) {
    height: 22px !important;
    width: 22px !important;
  }
  @media only screen and (min-width: 992px) {
  }
`;
const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  width: 345px;
  height: 48px;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  letter-spacing: 0.32px !important;
  padding-bottom: 20px;
  color: white !important;
  text-align: center;
  line-height: 0 !important;
  margin: 0 !important;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;

const ActionPlan3 = (props) => {
  window.scrollTo(0, 0);
  const [UserName, setUser] = useState('');
  const [Tile1, setTile1] = useState(false);
  const [Tile2, setTile2] = useState(false);
  const [Tip, setTip] = useState(false);
  const handleButtonClick = () => {
    props.store.set('currentActionPlan', '4');
  };
  const handleBackClick = () => {
 
    props.store.set('currentActionPlan', '2'); 
  };
  const handleExpandTile1 = () => {
    setTip(true);
    if (Tile1) {
      setTile1(false);
    } else {
      setTile1(true);
    }
  };
  const handleExpandTile2 = () => {
    setTip(true);
    if (Tile2) {
      setTile2(false);
    } else {
      setTile2(true);
    }
  };

  function AP3Content() {
    return (
      <GrayContainer>
        <WhiteContainer>
          <Grid columns={3} divided inverted style={{ margin: '0' }}>
            <Grid.Row style={{ padding: '0 0 14 0' }}>
              <Grid.Column
                style={{
                  width: '10%',
                  margin: '0',
                  paddingLeft: '15px',
                  textAlign: 'left',
                }}
              >
                <Icon.Group
                  size="large"
                  style={{ paddingTop: '7px', height: '30px', width: '30px' }}
                >
                  <IconCircle aria-hidden="false" size="large" name="circle" />
                  <IconAP3>
                    <form>
                      <fieldset>
                      <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                      </fieldset>
                    </form>
                  </IconAP3>
                </Icon.Group>
              </Grid.Column>
              <Grid.Column style={{ width: '80%' }}>
                <PageNumber>3/7</PageNumber>
                <PageTitle>
                  National DPP Information <br /> & Cost
                </PageTitle>
              </Grid.Column>
              <Grid.Column style={{ width: '10%' }} />
            </Grid.Row>
          </Grid>
          <BlueTextContainer>
            Awesome work! Now, let’s set your plan up for success. This program
            was created for people just like you - people ready to creative
            positive change in their lives and improve their health.
          </BlueTextContainer>
          <TextContainer>
            <ExpandingTile onClick={handleExpandTile1}>
              {' '}
              <SuccessTitle>What does the program look like?</SuccessTitle>{' '}
              {Tile1 ? (
                <Image
                  alt="collapse chevron"
                  style={{
                    display: 'inline-block',
                    float: 'right',
                    height: '22px',
                  }}
                  src="assets/step3_expand less.svg"
                />
              ) : (
                <Image
                  alt="expand chevron"
                  style={{
                    display: 'inline-block',
                    float: 'right',
                    height: '22px',
                  }}
                  src="assets/step3_expand_more.svg"
                />
              )}
            </ExpandingTile>
            {Tile1 ? (
              <div>
                <Image alt="info icon" src="assets/step3_programinfo.svg" />
                <AP3Text>
                  The program offers a year of support for healthy lifestyle
                  change and is backed by the Centers for Disease Control &
                  Prevention (CDC). For the first six months, you’ll attend
                  weekly clases with a group of participants and a trained
                  lifestyle coach. For the final six months, you’ll meet with
                  your coach and group once or twice a month. Did we mention you
                  could participate in person or online? Each program provider
                  is unique, (though all follow CDC guidelines), so you can find
                  the one that fits for your life!
                </AP3Text>
              </div>
            ) : (
              ''
            )}
            <AP3Line></AP3Line>
            <ExpandingTile onClick={handleExpandTile2}>
              {' '}
              <SuccessTitle>What does the program cost?</SuccessTitle>{' '}
              {Tile2 ? (
                <Image
                  alt="collapse chevron"
                  style={{
                    display: 'inline-block',
                    float: 'right',
                    height: '22px',
                  }}
                  src="assets/step3_expand less.svg"
                />
              ) : (
                <Image
                  alt="expand chevron"
                  style={{
                    display: 'inline-block',
                    float: 'right',
                    height: '22px',
                  }}
                  src="assets/step3_expand_more.svg"
                />
              )}
            </ExpandingTile>
            {Tile2 ? (
              <div>
                <Image alt="cost icon" src="assets/step3_programcost.svg" />
                <AP3Text>
                  Many insurance plans (including Medicare and some state
                  Medicaid programs) cover the cost of the program, and some are
                  offered for free or at a reduced price! Some employers cover
                  it too. Contact the program provider for more details on
                  pricing.
                </AP3Text>
              </div>
            ) : (
              ''
            )}
          </TextContainer>
          {Tip ? (
            <SuccessTip>
              <SucessTextBox>
                <Image
                  src="assets/tip icon.svg"
                  alt="tip icon"
                  style={{
                    width: '17px',
                    height: '17px',
                    display: 'inline-block',
                    marginRight: '13px',
                  }}
                ></Image>
                <SuccessTitle style={{ display: 'inline' }}>Tip</SuccessTitle>
                <TipText>
                  Have your health insurance information ready when you contact
                  the program provider of your choice.
                </TipText>
              </SucessTextBox>
            </SuccessTip>
          ) : (
            ''
          )}
          <CustomButton onClick={handleButtonClick}>
            Identify Barriers
          </CustomButton>
        </WhiteContainer>
      </GrayContainer>
    );
  }

  return (
    <Container>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <AP3Content />
      </Responsive>
      <Responsive {...Responsive.onlyTablet}>
        <AP3Content />
      </Responsive>

      <Responsive {...Responsive.onlyMobile}>
        <AP3Content />
      </Responsive>
    </Container>
  );
};

export default withStore(ActionPlan3);
