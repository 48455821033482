import React, {useState} from 'react';
import { Grid, List, Image, Icon, Responsive } from 'semantic-ui-react';
import styled from 'styled-components';
import { Padding } from './Styles';
// import Icon1 from '../../../assets/icon1.svg';
// import Icon2 from '../../../assets/icon2.svg';

const styles = {
  item: {
    color: '#fff',
  },
};

const Footer = styled.footer`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
color: #000;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: var(--defaults-fontsize);
box-sizing: border-box;
display: block;`;

const FooterWrapper = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
box-sizing: border-box;
width: 100%;
margin-right: auto;
margin-left: auto;
font-size: 13px;
margin-top: 1rem;
color: #fff;
padding: 0;
background-color: #333;`;

const Container = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: 13px;
color: #fff;
box-sizing: border-box;
width: 100%;
margin-right: auto;
margin-left: auto;
max-width: 72vw;
padding: 0;`;

const Footer2022 = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: 13px;
color: #fff;
box-sizing: border-box;
margin-top: 0 !important;`;

const FooterDesktop = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: 13px;
color: #fff;
box-sizing: border-box;
display: block;
padding: 0.75rem 1rem;`;

const Row = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: 13px;
color: #fff;
box-sizing: border-box;
display: flex;
flex-wrap: wrap;
margin-right: -15px;
margin-left: -15px;`;

const Col = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: 13px;
color: #fff;
box-sizing: border-box;
position: relative;
width: 100%;
padding-right: 15px;
padding-left: 15px;
flex: 0 0 50%;`;

const Dflex = styled.ul`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: 13px;
color: #fff;
box-sizing: border-box;
display: flex !important;
padding-left: 0;
list-style-type: none;
margin-bottom: 0;
margin-top: 0.5rem;`;

const Li = styled.li`
font-weight: 400;
line-height: 1.5;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
color: #fff;
list-style-type: none;
box-sizing: border-box;
margin-bottom: 3px;
font-size: 15px;
margin-right: 10%;`;

const A = styled.a`
font-weight: 400;
line-height: 1.5;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
list-style-type: none;
font-size: 15px;
box-sizing: border-box;
background-color: transparent;
color: #fff;
text-decoration: none;`;

const NoWrap = styled.div`
font-weight: 400;
line-height: 1.5;
text-align: left;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: 13px;
color: #fff;
box-sizing: border-box;
display: flex !important;
flex-wrap: nowrap !important;
justify-content: flex-end !important;
margin-left: -15px;`;

const SocialA = styled.a`
font-weight: 400;
line-height: 1.5;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: 13px;
box-sizing: border-box;
background-color: transparent;
color: #fff;
text-decoration: none;
width: 10%;
padding: 0 15px;
padding-right: 0;
transition: filter 0.5s;
text-align: right;`;

const SocialSpan = styled.span`
font-weight: 400;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
box-sizing: border-box;
text-decoration: none !important;
position: relative;
vertical-align: middle;
display: flex;
justify-content: center;
align-items: center;
font-size: 30px;
color: #fff;
line-height: 30px;
height: 30px;
width: 30px;
border-radius: 50%;
background: #666;
text-align: center;
overflow: hidden;
&:hover {
  filter: brightness(1.5) !important;
}`;

const SocialSrSpan = styled.span`
font-weight: 400;
line-height: 1.5;
font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
font-size: 13px;
color: #fff;
text-align: right;
box-sizing: border-box;
text-decoration: none !important;
position: absolute;
width: 1px;
height: 1px;
padding: 0;
margin: -1px;
overflow: hidden;
clip: rect(0, 0, 0, 0);
white-space: nowrap;
border: 0;
display: block;`;

const AgencyFooter = styled.div`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    font-size: 13px;
    padding-top: 0.5rem;
    padding-bottom: 3rem;
    margin-bottom: 1rem;`

const FooterAgencyRow = styled.div`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 0 !important;`

const LiDNone = styled.li`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    box-sizing: border-box;
    display: block !important;
    margin-bottom: 1em;`

const Ul = styled.ul`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 0;
    flex-wrap: wrap;`

const AgencyContainer = styled.div`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    box-sizing: border-box;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    max-width: 72vw;`

const AgencyA = styled.a`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    box-sizing: border-box;
    background-color: transparent;
    text-decoration: none !important;
    color: black;`

const FooterMobile = styled.div`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    font-size: 13px;
    color: #fff;
    background-color: #333;
    padding: 0;
    display: block;`

const UlMobile = styled.ul`
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    color: #fff;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 1rem !important;
    list-style-type: none;
    padding: 1em;
    padding-top: 0;`

const LiMobile = styled.li`
    font-size: 20px;
    color: #fff;
    list-style-type: none;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    border: none;
    height: auto;
    margin-bottom: 0 !important;
    overflow: visible;
    border-bottom: 0;
    border-radius: 0;
    background-color: transparent;`

const ButtonMobile = styled.button`
    list-style-type: none;
    word-wrap: break-word;
    box-sizing: border-box;
    margin: 0;
    line-height: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    font-weight: 300;
    font-family: "Open Sans", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    position: relative;
    border-radius: 0 !important;
    border: 0;
    text-align: left;
    transition: border-left-color 0.5s;
    transition-timing-function: ease;
    width: 100%;
    font-size: 1rem;
    padding: 1rem;
    border-left: 0;
    border-bottom: 1px solid #fff;
    margin-bottom: 0;
    border-top: 0 !important;
    cursor: pointer;
    border-left-color: #333 !important;
    background-color: transparent !important;
    color: #fff !important;`

const MobileCollapse = styled.div`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    color: #fff;
    list-style-type: none;
    word-wrap: break-word;
    box-sizing: border-box;`

const MobileCard = styled.div`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    color: #fff;
    list-style-type: none;
    word-wrap: break-word;
    box-sizing: border-box;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1rem;
    background-color: #333;`

const MobileAddress = styled.address`
    font-weight: 400;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    color: #fff;
    list-style-type: none;
    word-wrap: break-word;
    box-sizing: border-box;
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;`

const MobileRow = styled.div`
    font-weight: 400;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    color: #fff;
    list-style-type: none;
    word-wrap: break-word;
    font-style: normal;
    line-height: inherit;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-top: 0.5rem !important;`

const MobileCol1 = styled.div`
    font-weight: 400;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    color: #fff;
    list-style-type: none;
    word-wrap: break-word;
    font-style: normal;
    line-height: inherit;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;`

const MobileCol = styled.div`
    font-weight: 400;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-size: 13px;
    color: #fff;
    list-style-type: none;
    word-wrap: break-word;
    font-style: normal;
    line-height: inherit;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;`

const MobileSpan = styled.span`
    font-weight: 400;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    color: #fff;
    list-style-type: none;
    word-wrap: break-word;
    font-style: normal;
    line-height: inherit;
    box-sizing: border-box;
    padding-left: 0.25rem !important;
    display: block;
    font-size: 1rem;`

const AboutUl = styled.ul`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    color: #fff;
    word-wrap: break-word;
    box-sizing: border-box;
    margin-top: 0;
    list-style-type: none;
    margin-bottom: 0;
    font-size: 1rem;
    padding-left: 0;`

const AboutLi = styled.li`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    color: #fff;
    word-wrap: break-word;
    list-style-type: none;
    font-size: 1rem;
    box-sizing: border-box;
    margin-bottom: 3px;`

const AboutA = styled.a`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    word-wrap: break-word;
    list-style-type: none;
    box-sizing: border-box;
    background-color: transparent;
    color: #fff;
    font-size: 1.25rem;
    text-decoration: none;`

const ConnectUl = styled.ul`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    color: #fff;
    word-wrap: break-word;
    box-sizing: border-box;
    margin-top: 0;
    margin-right: -15px;
    margin-left: -15px;
    column-count: 4;
    list-style-position: outside;
    list-style-type: none;
    margin-bottom: 0;
    display: flex;
    flex-wrap: wrap;
    font-size: 1rem;
    padding-left: 0;`

const ConnectCol = styled.div`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    color: #fff;
    word-wrap: break-word;
    list-style-position: outside;
    list-style-type: none;
    font-size: 1rem;
    box-sizing: border-box;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    flex: 0 0 100%;
    max-width: 100%;
    display: flex;`

const LangUl = styled.ul`
    font-weight: 400;
    line-height: 1.5;
    font-family: "Nunito", apple-system, blinkmacsystemfont, "Segoe UI", "Helvetica Neue", arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    color: #fff;
    word-wrap: break-word;
    box-sizing: border-box;
    margin-top: 0;
    column-count: 2;
    list-style-position: outside;
    list-style-type: none;
    margin-bottom: 0;
    font-size: 1rem;
    padding-left: 0;`

export default (props) => {
  const [mobileOpen, setMobileOpen] = useState("");

  const handleMobileOpen = (newValue) => {
    console.log(newValue)
    setMobileOpen(newValue);
  }

  return (
    <>
    <Responsive minWidth={Responsive.onlyComputer.minWidth}>
      <Footer className="" role="contentinfo" aria-label="Footer">
        <FooterWrapper className="container-fluid footer-wrapper">
          <Container className="container">
            <Footer2022 className="footer-2022">
              <FooterDesktop className="footer-desktop">
                <Row className="row">
                  <Col className="col-6 about">
                    <Dflex className="d-flex">
                      <Li>
                        <A href="https://www.cdc.gov/about/deafult.htm">About CDC</A>
                      </Li>
                      <Li>
                        <A href="https://www.cdc.gov/cdc-info/index.html">Contact Us</A>
                      </Li>
                      <Li>
                        <A href="tel:8002324636">800-232-4636</A>
                      </Li>
                    </Dflex>
                  </Col>
                  <Col className="col-6 social">
                    <NoWrap className="d-flex flex-nowrap">
                      <SocialA href="https://www.facebook.com/CDC" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-facebook-inverse">
                          <Icon style={{width: "45%", height: "45%", display: "block", fill: "currentColor", transform: "translate(2px, -3px)"}}>
                            <img src="assets/facebook.svg" alt="Facebook" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">Facebook</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://twitter.com/CDCgov" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-twitter-inverse">
                          <Icon style={{width: "70%", height: "70%", display: "block", fill: "currentColor", transform: "translateX(2px)"}}>
                            <img src="assets/twitter.svg" alt="Twitter" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">Twitter</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://www.instagram.com/CDCgov/" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-instagram-inverse">
                          <Icon style={{width: "70%", height: "70%", display: "block", fill: "currentColor", transform: "translate(2px, -2px)"}}>
                            <img src="assets/instagram.svg" alt="Instagram" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">Instagram</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://www.linkedin.com/company/centers-for-disease-control-and-prevention" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-linkedin-inverse">
                          <Icon style={{width: "60%", height: "60%", display: "block", fill: "currentColor", transform: "translate(2px, -2px)"}}>
                            <img src="assets/linkedin.svg" alt="Instagram" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">LinkedIn</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://www.youtube.com/c/cdc/" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-youtube-inverse">
                          <Icon style={{width: "70%", height: "70%", display: "block", fill: "currentColor", transform: "translateX(2px)"}}>
                            <img src="assets/youtube.svg" alt="Youtube" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">YouTube</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://www.pinterest.com/cdcgov/" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-pinterest-inverse">
                          <Icon style={{width: "60%", height: "60%", display: "block", fill: "currentColor", transform: "translate(2px, -2px)"}}>
                            <img src="assets/pinterest.svg" alt="Pinterest" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">Pinterest</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://www.snapchat.com/add/cdcgov" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-snapchat-inverse">
                          <Icon style={{width: "70%", height: "70%", display: "block", fill: "currentColor", transform: "translate(1px, -1px)"}}>
                            <img src="assets/snapchat.svg" alt="Snapchat" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">Snapchat</SocialSrSpan>
                      </SocialA>
                    </NoWrap>
                  </Col>
                </Row>
              </FooterDesktop>
            </Footer2022>
          </Container>
        </FooterWrapper>
        <AgencyFooter className="container-fluid agency-footer">
          <AgencyContainer className="container">
            <FooterAgencyRow className="footer-2022 footer-agency row">
              <Col className="col">
                <Ul style={{width: "72vw"}}>
                  <LiDNone>
                    <AgencyA href="https://www.hhs.gov" style={{color: "#000", fontSize: "13px", border: "none", borderBottom: "none"}}>U.S. Department of Health & Human Services</AgencyA>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/other/accessibility.html" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>Accessibility</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/Other/disclaimer.html" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>External Links</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/other/privacy.html" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>Privacy</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/Other/policies.html" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>Policies</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/oeeowe/nofearact/" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>No Fear Act</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/od/foia" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>FOIA</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/other/nondiscrimination.html" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>Nondiscrimination</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://oig.hhs.gov" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>OIG</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html" style={{color: "#000", fontSize: "13px", textDecoration: "none !important"}}>Vulnerability Disclosure Policy</a>
                  </LiDNone>
                  <LiDNone>
                    <a href="https://www.usa.gov/" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>USA.gov</a>
                  </LiDNone>
                </Ul>
              </Col>
            </FooterAgencyRow>
          </AgencyContainer>
        </AgencyFooter>
      </Footer>
    </Responsive>
    <Responsive {...Responsive.onlyTablet}>
      <Footer className="" role="contentinfo" aria-label="Footer">
        <FooterWrapper className="container-fluid footer-wrapper">
          <Container className="container">
            <Footer2022 className="footer-2022">
              <FooterDesktop className="footer-desktop">
                <Row className="row" style={{display: "flex", justifyContent: "space-between"}}>
                  <Col className="col-6 about">
                    <Dflex className="d-flex">
                      <Li>
                        <A href="https://www.cdc.gov/about/deafult.htm">About CDC</A>
                      </Li>
                      <Li>
                        <A href="https://www.cdc.gov/cdc-info/index.html">Contact Us</A>
                      </Li>
                      <Li>
                        <A href="tel:8002324636">800-232-4636</A>
                      </Li>
                    </Dflex>
                  </Col>
                  <Col className="col-6 social">
                    <NoWrap className="d-flex flex-nowrap">
                      <SocialA href="https://www.facebook.com/CDC" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-facebook-inverse">
                          <Icon style={{width: "45%", height: "45%", display: "block", fill: "currentColor", transform: "translate(2px, -3px)"}}>
                            <img src="assets/facebook.svg" alt="Facebook" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">Facebook</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://twitter.com/CDCgov" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-twitter-inverse">
                          <Icon style={{width: "70%", height: "70%", display: "block", fill: "currentColor", transform: "translateX(2px)"}}>
                            <img src="assets/twitter.svg" alt="Twitter" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">Twitter</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://www.instagram.com/CDCgov/" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-instagram-inverse">
                          <Icon style={{width: "70%", height: "70%", display: "block", fill: "currentColor", transform: "translate(2px, -2px)"}}>
                            <img src="assets/instagram.svg" alt="Instagram" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">Instagram</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://www.linkedin.com/company/centers-for-disease-control-and-prevention" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-linkedin-inverse">
                          <Icon style={{width: "60%", height: "60%", display: "block", fill: "currentColor", transform: "translate(2px, -2px)"}}>
                            <img src="assets/linkedin.svg" alt="Instagram" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">LinkedIn</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://www.youtube.com/c/cdc/" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-youtube-inverse">
                          <Icon style={{width: "70%", height: "70%", display: "block", fill: "currentColor", transform: "translateX(2px)"}}>
                            <img src="assets/youtube.svg" alt="Youtube" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">YouTube</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://www.pinterest.com/cdcgov/" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-pinterest-inverse">
                          <Icon style={{width: "60%", height: "60%", display: "block", fill: "currentColor", transform: "translate(2px, -2px)"}}>
                            <img src="assets/pinterest.svg" alt="Pinterest" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">Pinterest</SocialSrSpan>
                      </SocialA>
                      <SocialA href="https://www.snapchat.com/add/cdcgov" target="_blank" rel="noreferrer noopener">
                        <SocialSpan className="cdc-icon-snapchat-inverse">
                          <Icon style={{width: "70%", height: "70%", display: "block", fill: "currentColor", transform: "translate(1px, -1px)"}}>
                            <img src="assets/snapchat.svg" alt="Snapchat" style={{filter: "invert(100%)"}}/>
                          </Icon>
                        </SocialSpan>
                        <SocialSrSpan className="sr-only">Snapchat</SocialSrSpan>
                      </SocialA>
                    </NoWrap>
                  </Col>
                </Row>
              </FooterDesktop>
            </Footer2022>
          </Container>
        </FooterWrapper>
        <AgencyFooter className="container-fluid agency-footer">
          <AgencyContainer className="container">
            <FooterAgencyRow className="footer-2022 footer-agency row">
              <Col className="col">
                <Ul style={{width: "72vw"}}>
                  <LiDNone>
                    <AgencyA href="https://www.hhs.gov" style={{color: "#000", fontSize: "13px", border: "none", borderBottom: "none"}}>U.S. Department of Health & Human Services</AgencyA>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/other/accessibility.html" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>Accessibility</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/Other/disclaimer.html" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>External Links</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/other/privacy.html" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>Privacy</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/Other/policies.html" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>Policies</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/oeeowe/nofearact/" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>No Fear Act</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/od/foia" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>FOIA</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.cdc.gov/other/nondiscrimination.html" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>Nondiscrimination</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://oig.hhs.gov" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>OIG</a>
                  </LiDNone>
                  <LiDNone className="d-none d-md-block">
                    <a href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html" style={{color: "#000", fontSize: "13px", textDecoration: "none !important"}}>Vulnerability Disclosure Policy</a>
                  </LiDNone>
                  <LiDNone>
                    <a href="https://www.usa.gov/" style={{color: "#000", fontSize: "13px", textDecoration: "none"}}>USA.gov</a>
                  </LiDNone>
                </Ul>
              </Col>
            </FooterAgencyRow>
          </AgencyContainer>
        </AgencyFooter>
      </Footer>
    </Responsive>
    <Responsive {...Responsive.onlyMobile}>
      <FooterMobile className="footer-mobile" aria-label="Footer" role="contentinfo">
        <UlMobile className="accordion indicator-arrow mb-3" role="tablist" aria-busy="true">
          <LiMobile className="card footer-mobile_contact" role="presentation">
            <div onClick={() => {if (mobileOpen !== "contact") {handleMobileOpen("contact")} else {handleMobileOpen("")}}} role="tab" style={{display: "flex", justifyContent:"space-between", borderBottom: "1px solid #fff", padding: "1rem"}}><span>CONTACT CDC</span><span>{mobileOpen === "contact" && (<img src="assets/close.svg" width="30px" height="30px" alt="Expand" style={{filter: "invert(100%)"}}/>)}{mobileOpen !== "contact" && (<img src="assets/down-arrow.svg" width="30px" height="30px" alt="Close" style={{filter: "invert(100%)"}}/>)}</span></div>
            { mobileOpen === "contact" && (
              <MobileCollapse className="collapse show" id="footer-mobile-collapse-1" role="tabpanel" aria-hidden="false" style>
              <MobileCard className="card-body">
                <MobileAddress itemscope="itemscope" itemtype="https://schema.org/Organization">
                  <MobileRow className="row mt-2">
                    <MobileCol1 className="col-1">
                      <img src="assets/computer-screen.svg" width="30px" height="30px" alt="Computer Screen" style={{filter: "invert(100%)"}}/>
                    </MobileCol1>
                    <MobileCol className="col">
                      <MobileSpan className="pl-1">
                        <a href="https://www.cdc.gov/cdc-info/index.html" style={{color: "#fff", fontSize: "1.25rem"}}>Contact Us</a>
                      </MobileSpan>
                    </MobileCol>
                  </MobileRow>
                  <MobileRow className="row mt-2">
                    <MobileCol1 className="col-1">
                      <img src="assets/phone.svg" width="30px" height="30px" alt="Phone" style={{filter: "invert(100%)"}}/>
                    </MobileCol1>
                    <MobileCol className="col">
                      <MobileSpan className="pl-1">
                        <span className="pl-1" itemprop="telephone" style={{color: "#fff", fontSize: "1.25rem"}}>Call 800-232-4636</span>
                      </MobileSpan>
                    </MobileCol>
                  </MobileRow>
                  <MobileRow className="row mt-2">
                    <MobileCol1 className="col-1">
                      <img src="assets/email.svg" width="30px" height="30px" alt="Email" style={{filter: "invert(100%)"}}/>
                    </MobileCol1>
                    <MobileCol className="col">
                      <MobileSpan className="pl-1">
                        <span className="pl-1" itemprop="url" style={{color: "#fff", fontSize: "1.25rem"}}>
                          <a href="https://www.cdc.gov/cdc-info/index.html" style={{color: "#fff", fontSize: "1.25rem"}}>Email Us</a>
                        </span>
                      </MobileSpan>
                    </MobileCol>
                  </MobileRow>
                </MobileAddress>
              </MobileCard>
            </MobileCollapse>)}
          </LiMobile>
          <LiMobile className="card footer-mobile_contact" role="presentation">
              <div onClick={() => { if (mobileOpen !== "about") { handleMobileOpen("about") } else { handleMobileOpen("") } }} role="tab" style={{display: "flex", justifyContent:"space-between", borderBottom: "1px solid #fff", padding: "1rem"}}><span>ABOUT CDC</span><span>{mobileOpen === "about" && (<img src="assets/close.svg" width="30px" height="30px" alt="Expand" role="tab" style={{filter: "invert(100%)"}}/>)}{mobileOpen !== "about" && (<img src="assets/down-arrow.svg" width="30px" height="30px" alt="Close" style={{filter: "invert(100%)"}}/>)}</span></div>
            { mobileOpen === "about" && (
              <MobileCollapse className="collapse show" id="footer-mobile-collapse-1" role="tabpanel" aria-hidden="false" style>
              <MobileCard className="card-body">
                <AboutUl>
                  <AboutLi>
                    <AboutA href="https://www.cdc.gov/about/default.html">About CDC</AboutA>
                  </AboutLi>
                  <AboutLi>
                    <AboutA href="https://jobs.cdc.gov">Jobs</AboutA>
                  </AboutLi>
                  <AboutLi>
                    <AboutA href="https://www.cdc.gov/funding">Funding</AboutA>
                  </AboutLi>
                </AboutUl>
              </MobileCard>
            </MobileCollapse>)}
          </LiMobile>
          <LiMobile className="card footer-mobile_contact" role="presentation">
              <div onClick={() => { if (mobileOpen !== "policies") { handleMobileOpen("policies") } else { handleMobileOpen("") } }} role="tab" style={{display: "flex", justifyContent:"space-between", borderBottom: "1px solid #fff", padding: "1rem"}}><span>POLICIES</span><span>{mobileOpen === "policies" && (<img src="assets/close.svg" width="30px" height="30px" alt="Expand" style={{filter: "invert(100%)"}}/>)}{mobileOpen !== "policies" && (<img src="assets/down-arrow.svg" width="30px" height="30px" alt="Close" style={{filter: "invert(100%)"}}/>)}</span></div>
            { mobileOpen === "policies" && (
              <MobileCollapse className="collapse show" id="footer-mobile-collapse-1" role="tabpanel" aria-hidden="false" style>
              <MobileCard className="card-body">
                <AboutUl>
                  <AboutLi>
                    <AboutA href="https://www.cdc.gov/other/accessibility.html">Accessibility</AboutA>
                  </AboutLi>
                  <AboutLi>
                    <AboutA href="https://www.cdc.gov/Other/disclaimer.html">External Links</AboutA>
                  </AboutLi>
                  <AboutLi>
                    <AboutA href="https://www.cdc.gov/other/privacy.html">Privacy</AboutA>
                  </AboutLi>
                  <AboutLi>
                    <AboutA href="https://www.cdc.gov/Other/policies.html">Policies</AboutA>
                  </AboutLi>
                  <AboutLi>
                    <AboutA href="https://www.cdc.gov/oeeowe/nofearact">No Fear Act</AboutA>
                  </AboutLi>
                  <AboutLi>
                    <AboutA href="https://www.cdc.gov/od/foia">FOIA</AboutA>
                  </AboutLi>
                  <AboutLi>
                    <AboutA href="https://oig.hhs.gov">OIG</AboutA>
                  </AboutLi>
                  <AboutLi>
                    <AboutA href="https://www.cdc.gov/other/nondiscrimination.html">Nondiscrimination</AboutA>
                  </AboutLi>
                  <AboutLi>
                    <AboutA href="https://www.hhs.gov/vulnerability-disclosure-policy/index.html">Vulnerability Disclosure Policy</AboutA>
                  </AboutLi>
                </AboutUl>
              </MobileCard>
            </MobileCollapse>)}
          </LiMobile>
          <LiMobile className="card footer-mobile_contact" role="presentation">
              <div onClick={() => { if (mobileOpen !== "connect") { handleMobileOpen("connect") } else { handleMobileOpen("") } }} role="tab" style={{display: "flex", justifyContent:"space-between", borderBottom: "1px solid #fff", padding: "1rem"}}><span>CONNECT WITH US</span><span>{mobileOpen === "connect" && (<img src="assets/close.svg" width="30px" height="30px" alt="Expand" style={{filter: "invert(100%)"}}/>)}{mobileOpen !== "connect" && (<img src="assets/down-arrow.svg" width="30px" height="30px" alt="Close" style={{filter: "invert(100%)"}}/>)}</span></div>
            { mobileOpen === "connect" && (
              <MobileCollapse className="collapse show" id="footer-mobile-collapse-1" role="tabpanel" aria-hidden="false" style>
              <MobileCard className="card-body">
                <ConnectUl className="bullet-list cc-4 lsp-out row">
                  <ConnectCol className="col-12" style={{display: "flex", justifyContent: "space-between"}}>
                    <AboutLi>
                      <AboutA href="https://www.facebook.com/CDC" target="_blank" rel="noreferrer noopener">
                      <img src="assets/facebook.svg" width="24px" height="24px" alt="Expand" style={{filter: "invert(100%)"}}/>
                      </AboutA>
                    </AboutLi>
                    <AboutLi>
                      <AboutA href="https://twitter.com/CDC" target="_blank" rel="noreferrer noopener">
                      <img src="assets/twitter.svg" width="24px" height="24px" alt="Expand" style={{filter: "invert(100%)"}}/>
                      </AboutA>
                    </AboutLi>
                    <AboutLi>
                      <AboutA href="https://www.instagram.com/CDCgov/" target="_blank" rel="noreferrer noopener">
                      <img src="assets/instagram.svg" width="24px" height="24px" alt="Expand" style={{filter: "invert(100%)"}}/>
                      </AboutA>
                    </AboutLi>
                    <AboutLi>
                      <AboutA href="https://www.linkedin.com/company/centers-for-disease-control-and-prevention" target="_blank" rel="noreferrer noopener">
                      <img src="assets/linkedin.svg" width="24px" height="24px" alt="Expand" style={{filter: "invert(100%)"}}/>
                      </AboutA>
                    </AboutLi>
                  </ConnectCol>
                  <ConnectCol className="col-12" style={{display: "flex", justifyContent: "space-between"}}>
                    <AboutLi>
                      <AboutA href="https://www.youtube.com/c/cdc" target="_blank" rel="noreferrer noopener">
                      <img src="assets/youtube.svg" width="24px" height="24px" alt="Expand" style={{filter: "invert(100%)"}}/>
                      </AboutA>
                    </AboutLi>
                    <AboutLi>
                      <AboutA href="https://www.pinterest.com/cdcgov" target="_blank" rel="noreferrer noopener">
                      <img src="assets/pinterest.svg" width="24px" height="24px" alt="Expand" style={{filter: "invert(100%)"}}/>
                      </AboutA>
                    </AboutLi>
                    <AboutLi>
                      <AboutA href="https://www.snapchat.com/add/cdcgov" target="_blank" rel="noreferrer noopener">
                      <img src="assets/snapchat.svg" width="24px" height="24px" alt="Expand" style={{filter: "invert(100%)"}}/>
                      </AboutA>
                    </AboutLi>
                    <AboutLi>
                      <AboutA href="https://wwwn.cdc.gov/dcs/RequestForm.aspx" target="_blank" rel="noreferrer noopener">
                      <img src="assets/email.svg" width="24px" height="24px" alt="Expand" style={{filter: "invert(100%)"}}/>
                      </AboutA>
                    </AboutLi>
                  </ConnectCol>
                </ConnectUl>
              </MobileCard>
            </MobileCollapse>)}
          </LiMobile>
          <LiMobile className="card footer-mobile_contact" role="presentation">
              <div onClick={() => { if (mobileOpen !== "langs") { handleMobileOpen("langs") } else { handleMobileOpen("") } }} role="tab" style={{display: "flex", justifyContent:"space-between", padding: "1rem"}}><span>LANGUAGES</span><span>{mobileOpen === "langs" && (<img src="assets/close.svg" width="30px" height="30px" alt="Expand" style={{filter: "invert(100%)"}}/>)}{mobileOpen !== "langs" && (<img src="assets/down-arrow.svg" width="30px" height="30px" alt="Close" style={{filter: "invert(100%)"}}/>)}</span></div>
            { mobileOpen === "langs" && (
              <MobileCollapse className="collapse show" id="footer-mobile-collapse-1" role="tabpanel" aria-hidden="false" style>
              <MobileCard className="card-body">
                <LangUl className="bullet-list cc-4 lsp-out row">
                  <AboutLi lang="es">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Spanish" title="Spanish">Español</AboutA>
                  </AboutLi>
                  <AboutLi lang="zh">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Chinese" title="Chinese">繁體中文</AboutA>
                  </AboutLi>
                  <AboutLi lang="vi">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Vietnamese" title="Vietnamese">Tiếng Việt</AboutA>
                  </AboutLi>
                  <AboutLi lang="ko">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Korean" title="Korean">한국어</AboutA>
                  </AboutLi>
                  <AboutLi lang="tl">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Tagalog" title="Tagalog">Tagalog</AboutA>
                  </AboutLi>
                  <AboutLi lang="ru">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Russian" title="Russian">Русский</AboutA>
                  </AboutLi>
                  <AboutLi lang="ar">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Arabic" title="Arabic">العربية</AboutA>
                  </AboutLi>
                  <AboutLi lang="ht">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Haitian" title="Haitian">Kreyòl Ayisyen</AboutA>
                  </AboutLi>
                  <AboutLi lang="fr">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#French" title="French">Français</AboutA>
                  </AboutLi>
                  <AboutLi lang="pl">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Polish" title="Polish">Polski</AboutA>
                  </AboutLi>
                  <AboutLi lang="pt">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Portugese" title="Portugese">Português</AboutA>
                  </AboutLi>
                  <AboutLi lang="it">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Italian" title="Italian">Italiano</AboutA>
                  </AboutLi>
                  <AboutLi lang="de">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#German" title="German">Deutsch</AboutA>
                  </AboutLi>
                  <AboutLi lang="ja">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Japanese" title="Japanese">日本語</AboutA>
                  </AboutLi>
                  <AboutLi lang="fa">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#Farsi" title="Farsi">فارسی</AboutA>
                  </AboutLi>
                  <AboutLi lang="en">
                    <AboutA href="https://www.cdc.gov/other/language-assistance.html#English" title="English">English</AboutA>
                  </AboutLi>
                </LangUl>
              </MobileCard>
            </MobileCollapse>)}
          </LiMobile>
        </UlMobile>
        <AgencyFooter className="container-fluid agency-footer" style={{backgroundColor: "#fff"}}>
          <AgencyContainer className="container">
            <FooterAgencyRow className="footer-2022 footer-agency row">
              <Col className="col">
                <Ul style={{width: "72vw"}}>
                  <LiDNone>
                    <AgencyA href="https://www.hhs.gov" style={{color: "#000", fontSize: "13px", border: "none", borderBottom: "none"}}>U.S. Department of Health & Human Services</AgencyA>
                  </LiDNone>
                  <LiDNone>
                    <AgencyA href="https://www.usa.gov" style={{color: "#000", fontSize: "13px", border: "none", borderBottom: "none"}}>USA.gov</AgencyA>
                  </LiDNone>
                </Ul>
              </Col>
            </FooterAgencyRow>
          </AgencyContainer>
        </AgencyFooter>
      </FooterMobile>
    </Responsive>
    </>
  );
};

