import React, { useState, Component } from 'react';
import styled from 'styled-components';
import { withStore } from '../../store';
import { Responsive, Button, Image, Icon, Grid } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import {
  GrayContainer,
  TextContainer,
  WhiteContainer,
  H5,
  BlueTextContainer,
  Container,
  PageTitleAP8,
  PageNumber,
  APContent,
  EnterNameText,
  InputName,
  ImageDiv,
  Ap8Subtext,
  SuccessMessage,
  SucessTextBox,
  TipText,
  SuccessTitle,
  EditPlanText,
} from './Styles';
import AddendumQuestions from '../AddendumQuestions';
import { render } from 'react-dom';
const Bullet = styled.div`
  display: inline-block;
  vertical-align: top;
  margin-left: 12px;
  @media only screen and (min-width: 768px) {
    margin-left: 42px !important;
  }
  @media only screen and (min-width: 992px) {
    margin-left: 42px !important;
  }
`;
const BulletTip = styled.div`
  display: inline-block;
  max-width: 315px;
  color: #333333;
  font-family: Nunito;
  font-size: 14px;
  letter-spacing: 0.2px;
  line-height: 19px;
  margin-bottom: 6px;
`;

const CustomButton = styled(Button)`
  background-color: #007c91 !important;
  width: 345px;
  height: 48px;
  font-family: Poppins !important;
  font-size: 22px !important;
  font-weight: 500 !important;
  letter-spacing: 0.32px !important;
  padding-bottom: 20px;
  color: white !important;
  text-align: center;
  line-height: 0 !important;
  margin: 0 !important;
  margin-top: 60px !important;
  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
  @media only screen and (min-width: 768px) {
    width: 324px;
    margin-left: 60px !important;
  }
  @media only screen and (min-width: 992px) {
    width: 324px;
    margin-left: 60px !important;
  }
`;
const ActionPlan8 = (props) => {
  window.scrollTo(0, 0);
  const history = useHistory();
  const handleButtonClick = () => {
    history.push('/findaprogram');
  };
  const handleBackClick = () => {
    props.store.set('currentActionPlan', '7'); 
  };
  const handleLearnMoreClick = () => {};

  return (
    <Container>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <GrayContainer>
          <WhiteContainer>
            <Grid columns={3} divided inverted style={{ margin: '0' }}>
              <Grid.Row style={{ padding: '0 0 14 0' }}>
                <Grid.Column
                  style={{
                    width: '10%',
                    margin: '0',

                    paddingLeft: '15px',
                    textAlign: 'left',
                  }}
                >
                  <Icon.Group
                    size="large"
                    style={{
                      paddingTop: '7px',
                      height: '30px',
                      width: '30px',
                    }}
                  >
                    <Icon
                      aria-hidden="false"
                      size="large"
                      name="circle"
                      style={{
                        color: '#F5F5F5',
                        fontSize: '30px',
                      }}
                    />
                    <Icon
                      aria-hidden="false"
                      style={{
                        paddingTop: '7px',
                        top: '50%',
                        left: '48%',
                      }}
                    >
                      <form>
                        <fieldset>
                        <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                        </fieldset>
                      </form>
                    </Icon>
                  </Icon.Group>
                </Grid.Column>
                <Grid.Column style={{ width: '80%' }}>
                  <PageNumber style={{ height: '30px' }}></PageNumber>
                  <PageTitleAP8>Congratulations!</PageTitleAP8>
                </Grid.Column>
                <Grid.Column style={{ width: '10%' }} />
              </Grid.Row>
            </Grid>
            <Ap8Subtext>
              View the tips on how to use your plan below!{' '}
            </Ap8Subtext>

            <ImageDiv>
              <Image
                src="assets/ActionPlanCongratsIllustration.svg"
                alt="completed"
                style={{
                  height: '102px',
                  width: '132px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              ></Image>
            </ImageDiv>
            <SuccessMessage
              style={{
                marginTop: '40px',
                maxWidth: '522px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <SucessTextBox>
                <Image
                  src="assets/tip icon.svg"
                  alt="tip icon"
                  style={{
                    width: '17px',
                    height: '17px',
                    display: 'inline-block',
                    marginRight: '13px',
                    marginLeft: '20px',
                  }}
                ></Image>
                <SuccessTitle style={{ display: 'inline' }}>
                  How to use my plan
                </SuccessTitle>
                <TipText>
                  <Bullet>•</Bullet>{' '}
                  <BulletTip>
                    Put your plan somewhere you can see it everyday{' '}
                  </BulletTip>
                  <br></br>
                  <Bullet>•</Bullet>{' '}
                  <BulletTip>Share your plan with your doctor</BulletTip>{' '}
                  <br></br>
                  <Bullet>•</Bullet>{' '}
                  <BulletTip>
                    Bring your plan to your first National DPP class
                  </BulletTip>{' '}
                  <br></br>
                </TipText>
              </SucessTextBox>
            </SuccessMessage>
            <a
              href="https://www.cdc.gov/diabetes/prevention/testimonials-participants.html"
              target="_blank"
            >
              <EditPlanText>Learn More About Prediabetes</EditPlanText>
            </a>
            <CustomButton onClick={handleButtonClick}>Continue</CustomButton>
          </WhiteContainer>
        </GrayContainer>
      </Responsive>
      <Responsive {...Responsive.onlyTablet}>
        <GrayContainer>
          <WhiteContainer>
            <Grid columns={3} divided inverted style={{ margin: '0' }}>
              <Grid.Row style={{ padding: '0 0 14 0' }}>
                <Grid.Column
                  style={{
                    width: '10%',
                    margin: '0',

                    paddingLeft: '15px',
                    textAlign: 'left',
                  }}
                >
                  <Icon.Group
                    size="large"
                    style={{
                      paddingTop: '7px',
                      height: '30px',
                      width: '30px',
                    }}
                  >
                    <Icon
                      size="large"
                      name="circle"
                      style={{
                        color: '#F5F5F5',
                        fontSize: '30px',
                      }}
                    />
                    <Icon
                      style={{
                        paddingTop: '7px',
                        top: '50%',
                        left: '48%',
                      }}
                    >
                      <form>
                        <fieldset>
                        <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                        </fieldset>
                      </form>
                    </Icon>
                  </Icon.Group>
                </Grid.Column>
                <Grid.Column style={{ width: '80%' }}>
                  <PageNumber style={{ height: '30px' }}></PageNumber>
                  <PageTitleAP8>Congratulations!</PageTitleAP8>
                </Grid.Column>
                <Grid.Column style={{ width: '10%' }} />
              </Grid.Row>
            </Grid>
            <Ap8Subtext>
              View the tips on how to use your plan below!{' '}
            </Ap8Subtext>

            <ImageDiv>
              <Image
                src="assets/ActionPlanCongratsIllustration.svg"
                alt="completed"
                style={{
                  height: '102px',
                  width: '132px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              ></Image>
            </ImageDiv>
            <SuccessMessage
              style={{
                marginTop: '40px',
                maxWidth: '522px',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <SucessTextBox>
                <Image
                  src="assets/tip icon.svg"
                  alt="tip icon"
                  style={{
                    width: '17px',
                    height: '17px',
                    display: 'inline-block',
                    marginRight: '13px',
                    marginLeft: '20px',
                  }}
                ></Image>
                <SuccessTitle style={{ display: 'inline' }}>
                  How to use my plan
                </SuccessTitle>
                <TipText>
                  <Bullet>•</Bullet>{' '}
                  <BulletTip>
                    Put your plan somewhere you can see it everyday{' '}
                  </BulletTip>
                  <br></br>
                  <Bullet>•</Bullet>{' '}
                  <BulletTip>Share your plan with your doctor</BulletTip>{' '}
                  <br></br>
                  <Bullet>•</Bullet>{' '}
                  <BulletTip>
                    Bring your plan to your first National DPP class
                  </BulletTip>{' '}
                  <br></br>
                </TipText>
              </SucessTextBox>
            </SuccessMessage>
            <a
              href="https://www.cdc.gov/diabetes/prevention/testimonials-participants.html"
              target="_blank"
            >
              <EditPlanText>Learn More About Prediabetes</EditPlanText>
            </a>
            <CustomButton onClick={handleButtonClick}>Continue</CustomButton>
          </WhiteContainer>
        </GrayContainer>
      </Responsive>

      <Responsive {...Responsive.onlyMobile}>
        <GrayContainer>
          <WhiteContainer>
            <Grid columns={3} divided inverted style={{ margin: '0' }}>
              <Grid.Row style={{ padding: '0 0 14 0' }}>
                <Grid.Column
                  style={{
                    width: '10%',
                    margin: '0',

                    paddingLeft: '15px',
                    textAlign: 'left',
                  }}
                >
                  <Icon.Group
                    size="large"
                    style={{
                      paddingTop: '7px',
                      height: '30px',
                      width: '30px',
                    }}
                  >
                    <Icon
                      size="large"
                      name="circle"
                      style={{
                        color: '#F5F5F5',
                        fontSize: '25px',
                      }}
                    />
                    <Icon
                      style={{
                        paddingTop: '7px',
                        top: '50%',
                        left: '40%',
                      }}
                    >
                      <form>
                        <fieldset>
                        <a href={void(0)} style={{'cursor': 'pointer'}}  onClick={() => handleBackClick(this)} >
                              <img  
                              aria-hidden="false"
                              alt="back button to main page"
                              src="assets/arrow_back-Plan.svg"
                              style={{ height: '22px', width: '22px' }} />
                            </a>
                        </fieldset>
                      </form>
                    </Icon>
                  </Icon.Group>
                </Grid.Column>
                <Grid.Column style={{ width: '80%' }}>
                  <PageNumber style={{ height: '30px' }}></PageNumber>
                  <PageTitleAP8>Congratulations!</PageTitleAP8>
                </Grid.Column>
                <Grid.Column style={{ width: '10%' }} />
              </Grid.Row>
            </Grid>
            <Ap8Subtext>
              View the tips on how to use your plan below!{' '}
            </Ap8Subtext>

            <ImageDiv>
              <Image
                src="assets/ActionPlanCongratsIllustration.svg"
                alt="completed"
                style={{
                  height: '102px',
                  width: '132px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              ></Image>
            </ImageDiv>
            <SuccessMessage style={{ marginTop: '40px' }}>
              <SucessTextBox>
                <Image
                  src="assets/tip icon.svg"
                  alt="tip icon"
                  style={{
                    width: '17px',
                    height: '17px',
                    display: 'inline-block',
                    marginRight: '13px',
                  }}
                ></Image>
                <SuccessTitle style={{ display: 'inline' }}>
                  How to use my plan
                </SuccessTitle>
                <TipText>
                  <Bullet>•</Bullet>{' '}
                  <BulletTip>
                    Put your plan somewhere you can see it everyday{' '}
                  </BulletTip>
                  <br></br>
                  <Bullet>•</Bullet>{' '}
                  <BulletTip>Share your plan with your doctor</BulletTip>{' '}
                  <br></br>
                  <Bullet>•</Bullet>{' '}
                  <BulletTip>
                    Bring your plan to your first National DPP class
                  </BulletTip>{' '}
                  <br></br>
                </TipText>
              </SucessTextBox>
            </SuccessMessage>
            <CustomButton onClick={handleButtonClick}>Continue</CustomButton>
            <a
              href="https://www.cdc.gov/diabetes/prevention/testimonials-participants.html"
              target="_blank"
            >
              <EditPlanText>Learn More About Prediabetes</EditPlanText>
            </a>
          </WhiteContainer>
        </GrayContainer>
      </Responsive>
    </Container>
  );
};
export default withStore(ActionPlan8);
