import React, { createRef, Component, Responsive, useEffect, useState } from 'react';
import { Route, Switch, NavLink } from 'react-router-dom';
import { Sticky } from 'semantic-ui-react';
import Home from './Home';
import About from './About';
import FindAProgram from './FindAProgram';
import NotFound from './NotFound';
import Header from './Header';
import Footer from './Footer';
import BeginPath from './BeginPath';
import LearnMore from './LearnMore';
import StartPlan from './StartPlan';
import AddendumQuestionsMobile from './AddendumQuestions/AddendumContent';
import { createStore } from '../store';
import Title from './Title';
// import window from 'global';
import { trigger_interaction, trigger_pageview } from './Utilities'

import styled from 'styled-components';

export const Skip = styled.a`
  background: #39536c;
  color: #fff;
  height: 30px;
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
  &:focus {
    transform: translateY(0%);
  }
`;

const App = () => {
  const [width, setWidth] = useState('');
  const contextRef = createRef();

  // UseEffect to handle window resize
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWidth(window.innerWidth);
      };

      window.addEventListener('resize', handleResize);

      // Cleanup the event listener on unmount
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    //trigger metrics
    trigger_pageview( {
      ch: 'OADC',
      pageName: document.title
  } );
  }, []);

  return (
    <div ref={contextRef}>
      <Switch>
        <Title exact path="/" title="Home" />
        <Title exact path="/home" title="Home" />
        <Title exact path="/about" title="About" />
        <Title exact path="/findaprogram" title="Find a Program" />
        <Title exact path="/beginpath" title="Begin Path" />
        <Title exact path="/learnmore" title="Learn More" />
        <Title exact path="/startplan" title="Start Plan" />
      </Switch>
      <Skip href="#main">Skip to content</Skip>
      <Header />
      <div id="main">
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => <Home name="Home" {...props} />}
          />
          <Route
            path="/home"
            render={(props) =>
              width < 768 ? (
                <AddendumQuestionsMobile />
              ) : (
                <Home name="Home" {...props} />
              )
            }
          />
          <Route exact path="/about" component={About} />
          <Route exact path="/findaprogram" component={FindAProgram} />
          <Route exact path="/beginpath" component={BeginPath} />
          <Route exact path="/learnmore" component={LearnMore} />
          <Route exact path="/startplan" component={StartPlan} />
          <Route
            exact
            path="/addendumquestions"
            component={AddendumQuestionsMobile}
          />
          <Route component={NotFound} status={404} />
        </Switch>
        <Footer />
      </div>
    </div>
  );
};

export default createStore(App);
