  function trigger_interaction(eventLabel = '', eventType, eventValue) {
    _check_initialized()
    _queue_action({
        function: '_trigger_interaction',
        eventLabel,
        eventType,
        eventValue
    })
    _process_queue()
  }
  
  function trigger_pageview(dataObject) {
    _check_initialized()
    _queue_action({
        function: '_trigger_pageview',
        dataObject
    })
    _process_queue()
  }
  
  function _check_initialized() {
    window.CDC = window.CDC || {};
    window.CDC.SPA_App = window.CDC.SPA_App || {};
  
    if(!window.CDC.SPA_App.initialized) {
        const bodyElement = document.getElementsByTagName('body')[0];
        if(bodyElement && bodyElement.className.indexOf('adobe-launch-complete') !== -1){
            window.CDC.SPA_App.initialized = true;
        } else if(!window.CDC.SPA_App.listenerAdded){
            window.CDC.SPA_App.listenerAdded = true;
            bodyElement.addEventListener('adobe-launch-loaded', function(){
                window.CDC.SPA_App.initialized = true;
                _process_queue();
            })
        }
    }
  }
  
  function _queue_action(action) {
    window.CDC.SPA_App.action_queue = window.CDC.SPA_App.action_queue || [];
    window.CDC.SPA_App.action_queue.push(action);
  }
  
  function _process_queue() {
    if(window.CDC.SPA_App.initialized && window.CDC.SPA_App.action_queue && window.CDC.SPA_App.action_queue.length > 0) {
        const actions = window.CDC.SPA_App.action_queue;
        window.CDC.SPA_App.action_queue = [];
  
        actions.forEach(function(action){
            if(action.function === '_trigger_interaction'){
                _trigger_interaction(action.eventLabel, action.eventType, action.eventValue);
            } else if(action.function === '_trigger_pageview'){
                _trigger_pageview(action.dataObject)
            }
        })
    }
  }
  
  function _trigger_interaction(eventLabel, eventType, eventValue) {
    if (window.hasOwnProperty( '_satellite' )) {
        var dataObject = {};
        eventType = eventType || 'o';
        dataObject.ch = 'OADC';
        dataObject.pageName = document.title;
        dataObject.prop40 = eventValue;
  
        dataObject.label = eventLabel;
        dataObject.interactionType = eventType;
        dataObject.interactionValue = 'ci-' + eventLabel + ': ' + eventValue;
  
        dataObject.prop46 = window.location.href;
  
        console.log('adobe launch spa satellite track: interaction', dataObject);
        window._satellite.track( 'interaction', dataObject );
    } else {
        console.error('Analytics library finished rendering but satellite is undefined');
    }
  }
  
  function _trigger_pageview(dataObject) {
    if (window.hasOwnProperty( '_satellite' )) {
        console.log('adobe launch spa satellite track: pageview', dataObject);
        window._satellite.track( 'pageview', dataObject );
    } else {
        console.error('Analytics library finished rendering but satellite is undefined');
    }
  }
  
  export { trigger_interaction, trigger_pageview }