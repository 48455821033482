import React, { Fragment } from 'react';
import { Responsive } from 'semantic-ui-react';
import { H4, H5 } from './Styles';
import { withStore } from '../../store';

const styles = {
    radio: {
        fontSize: '18px',
        letterSpacing: '0.26px',
        fontWeight: '500',
        fontFamily: 'Poppins !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    radioContainer: (isSelected) => ({
        marginBottom: '10px',
        border: '2.5px solid',
        borderColor: isSelected ? '#39536c' : '#bed0e1',
        padding: '14px',
        width: '100%',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        minHeight: '52px'
    }),
    desktopContainer: (isSelected) => ({
        display: 'flex',
        alignItems: 'center',
        marginRight: '10px',
        border: '2.5px solid',
        borderColor: isSelected ? '#39536c' : '#bed0e1',
        padding: '14px',
        backgroundColor: 'white',
        boxSizing: 'border-box',
        width: '160px',
        height: '52px',
        textAlign: 'left'
    }),
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        cursor: 'pointer'
    },
    fakeRadio: (isSelected) => ({
        width: '18px',
        height: '18px',
        minWidth: '18px',
        borderRadius: '50%',
        border: '2px solid #39536c',
        marginRight: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        backgroundColor: 'white'
    }),
    innerCircle: {
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: '#39536c'
    },
    groupContainer: {
        display: 'inline-flex',
        gap: '10px',
        justifyContent: 'center',
        width: '100%'
    }
};

const questions = {
    1: {
        text1: 'Are you actively taking steps to eat healthy and/or be more physically active?',
        answers: ['Yes', 'No'],
        text2: ' Examples of healthy eating include cutting back on the amount of sugar and processed food you eat and/or eating more vegetables and lean meats. Examples of being more physically active include adding more walking or exercise into your daily routine.',
    },
    2: {
        text1: 'How likely are you to work toward making changes like eating healthier or being more physically active in the next 6 months? ',
        answers: ['Likely', 'Somewhat Likely', 'Not Likely'],
        text2: 'Increased physical activity and healthier eating are two key habits that can reduce your risk of developing type 2 diabetes.',
    },
    3: {
        text1: 'Would you consider enrolling in the National Diabetes Prevention Program in the next 30 days? ',
        answers: ['Yes', 'No'],
        text2: 'The National Diabetes Prevention Program (National DPP) gives you access to a trained coach that can help you add healthy behaviors to your lifestyle. Individuals enrolled in the National DPP can cut their chances of developing type 2 diabetes in half. ',
    },
};

const Question1 = (props) => {
    const handleChange = (val) => {
        props.store.set('error', false);
        if (val == props.store.answer1) {
            props.store.set('answer1', '');
            props.store.set('currentQuestion', '1');
        } else {
            props.store.set('answer1', val);
            val == 'No'
                ? props.store.set('currentQuestion', '2')
                : props.store.set('currentQuestion', '1');
        }
    };

    const handleKeyDown = (e, val) => {
        if (e.key === ' ' || e.key === 'Enter') {
            e.preventDefault();
            handleChange(val);
        }
    };

    return (
        <Fragment>
            <H5>Question 1</H5>
            <br />
            <H4 id="question1-label">{questions[1].text1}</H4>
            <br />

            <Responsive {...Responsive.onlyMobile}>
                <div role="group" aria-labelledby="question1-label">
                    {questions[1].answers.map((val, index) => {
                        const isSelected = props.store.answer1 == val;
                        return (
                            <div
                                key={index}
                                style={styles.radioContainer(isSelected)}
                            >
                                <div
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => handleChange(val)}
                                    onKeyDown={(e) => handleKeyDown(e, val)}
                                    style={styles.buttonWrapper}
                                    aria-pressed={isSelected}
                                >
                                    <span style={styles.radio}>
                                        <div style={styles.fakeRadio(isSelected)}>
                                            {isSelected && <div style={styles.innerCircle} />}
                                        </div>
                                        {val}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Responsive>

            <Responsive
                minWidth={Responsive.onlyTablet.minWidth}
            >
                <div
                    role="group"
                    aria-labelledby="question1-label"
                    style={styles.groupContainer}
                >
                    {questions[1].answers.map((val, index) => {
                        const isSelected = props.store.answer1 == val;
                        return (
                            <div
                                key={index}
                                style={styles.desktopContainer(isSelected)}
                            >
                                <div
                                    role="button"
                                    tabIndex={0}
                                    onClick={() => handleChange(val)}
                                    onKeyDown={(e) => handleKeyDown(e, val)}
                                    style={styles.buttonWrapper}
                                    aria-pressed={isSelected}
                                >
                                    <span style={styles.radio}>
                                        <div style={styles.fakeRadio(isSelected)}>
                                            {isSelected && <div style={styles.innerCircle} />}
                                        </div>
                                        {val}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Responsive>

            <br />
            <H5
                id="question1-description"
                style={{ color: '#333333' }}
            >
                {questions[1].text2}
            </H5>
        </Fragment>
    );
};

export default withStore(Question1);