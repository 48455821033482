import React, {useEffect} from 'react';

export default ({title}) => {

  useEffect(() => {

    const ptitle = title

      ? `CDC - Path 2 Prevention | ${title}`

      : 'CDC - Path 2 Prevention';

    // console.log({ ptitle });

    document.title = ptitle;

    //check for satellite object per https://webinfo.cdc.gov/display/WCMSSP/CDC+Metrics+-+Adobe+Launch

    /*if (window.hasOwnProperty('_satellite')) {

      var dataObject = {};

      var _satellite = window._satellite;

      dataObject.ch = 'PATH2PREVENTION';

      dataObject.pageName = document.title;

      _satellite.track('pageview', dataObject);

    }*/

    // use dependency array to only run useEffect once per title change (needed for metrics)

  }, [title]);

  return (
    <div />
  );

};