import React, { useState } from 'react';
import {
  Placeholder,
  Button,
  Responsive,
  Icon,
  Image,
} from 'semantic-ui-react';

import { GreenHyperlink, ContinueButton, Text, H2, H1 } from './Styles';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;
const CustomButton = styled(Button)`
  background-color: #007c91 !important;

  &:hover {
    background-color: #016374 !important;
    color: white !important;
  }
`;
export default (props) => {
  const history = useHistory();

  const handleLearnMoreClick = () => {
    history.push('/learnmore');
  };
  return (
    <Container>
      <Responsive minWidth={Responsive.onlyComputer.minWidth}>
        <div
          style={{
            maxWidth: '1140px',
            backgroundColor: '#FFF',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: '18px',
          }}
        >
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '26px',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                display: 'inline-Block',
                marginRight: '41px',
                marginTop: '4px',
                verticalAlign: 'top',
              }}
            >
              <Image
                size="medium"
                src="assets/Video_Icon.svg"
                alt="Lisa's story video icon"
                style={{ height: '50px', width: '50px' }}
              ></Image>
            </div>
            <div
              style={{
                textAlign: 'center',
                display: 'inline-Block',
                maxWidth: '726px',
              }}
            >
              <div>
                <H1> Welcome to Lisa’s Story!</H1>
              </div>
              <H2>
                A short video where you’ll learn about prediabetes and some
                things you can do to reverse it. Click play in the video below
                to learn more!{' '}
              </H2>
            </div>
            <div style={{ textAlign: 'center', display: 'inline-Block' }}></div>
          </div>
        </div>
        <div
          style={{
            maxWidth: '1140px',
            backgroundColor: '#F2F8FD',

            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '16px',
            paddingBottom: '22px',
          }}
        >
          {' '}
          <iframe
            width="725"
            height="403"
            src="https://www.youtube.com/embed/azKL5xutMJE"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            aria-label="lisa's story"
          ></iframe>
        </div>
        <div
          style={{
            maxWidth: '1140px',
            backgroundColor: '#FFF',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: '18px',
            marginBottom: '50px',
          }}
        >
          <div
            style={{
              maxWidth: '920px',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '26px',
            }}
          >
            {' '}
            <Text>
              Continue on your Path 2 Prevention below by learning more about
              prediabetes and the National DPP, or move to our Myth vs. Fact
              game where you’ll get to test your knowledge about prediabetes and
              type 2 diabetes.
            </Text>
            <div
              style={{
                display: 'inline-block',
                textAlign: 'left',
                width: '50%',
              }}
            >
              <GreenHyperlink
                href="https://www.cdc.gov/diabetes-prevention/about-prediabetes-type-2/index.html"
                target="_blank"
              >
                Learn More About Prediabetes
              </GreenHyperlink>
            </div>
            <div
              style={{
                display: 'inline-block',
                textAlign: 'right',
                width: '50%',
              }}
            >
              <ContinueButton onClick={handleLearnMoreClick}>
                Continue
              </ContinueButton>
            </div>
          </div>
        </div>
      </Responsive>

      <Responsive {...Responsive.onlyTablet}>
        <div
          style={{
            maxWidth: '690px',
            backgroundColor: '#FFF',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: '18px',
          }}
        >
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '26px',
            }}
          >
            <div
              style={{
                textAlign: 'center',
                display: 'inline-Block',
                marginRight: '16px',
                marginTop: '4px',
                verticalAlign: 'top',
              }}
            >
              <Image
                size="medium"
                src="assets/Video_Icon.svg"
                alt="Lisa's story video icon"
                style={{ height: '50px', width: '50px' }}
              ></Image>
            </div>
            <div
              style={{
                textAlign: 'center',
                display: 'inline-Block',
                maxWidth: '572px',
              }}
            >
              <div>
                <H1> Welcome to Lisa’s Story!</H1>
              </div>
              <H2>
                A short video where you’ll learn about prediabetes and some
                things you can do to reverse it. Click play in the video below
                to learn more!{' '}
              </H2>
            </div>
            <div style={{ textAlign: 'center', display: 'inline-Block' }}></div>
          </div>
        </div>
        <div
          style={{
            maxWidth: '690px',
            backgroundColor: '#F2F8FD',

            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '38px',
            paddingBottom: '25px',
          }}
        >
          {' '}
          <iframe
            width="601"
            height="334"
            src="https://www.youtube.com/embed/azKL5xutMJE"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            aria-label="lisa's story"
          ></iframe>
        </div>
        <div
          style={{
            maxWidth: '690px',
            backgroundColor: '#FFF',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingBottom: '41px',
            marginBottom: '26px',
          }}
        >
          <div
            style={{
              maxWidth: '601px',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '20px',
            }}
          >
            {' '}
            <Text>
              Continue on your Path 2 Prevention below by learning more about
              prediabetes and the National DPP, or move to our Myth vs. Fact
              game where you’ll get to test your knowledge about prediabetes and
              type 2 diabetes.
            </Text>
            <div
              style={{
                display: 'inline-block',
                textAlign: 'left',
                width: '50%',
              }}
            >
              <GreenHyperlink
                href="https://www.cdc.gov/diabetes-prevention/about-prediabetes-type-2/index.html"
                target="_blank"
              >
                Learn More About Prediabetes
              </GreenHyperlink>
            </div>
            <div
              style={{
                display: 'inline-block',
                textAlign: 'right',
                width: '50%',
              }}
            >
              <ContinueButton onClick={handleLearnMoreClick}>
                Continue
              </ContinueButton>
            </div>
          </div>
        </div>
      </Responsive>
      {/* mobile responive below! */}
      <Responsive {...Responsive.onlyMobile}>
        <div
          style={{
            backgroundColor: '#FFF',
            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '19px',
            paddingBottom: '7px',
          }}
        >
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '0',
            }}
          >
            <div
              style={{
                textAlign: 'center',

                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: '0px',
                paddingBottom: '13px',
              }}
            >
              <Image
                size="medium"
                src="assets/Video_Icon.svg"
                alt="Lisa's story video icon"
                style={{
                  height: '50px',
                  width: '50px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              ></Image>
            </div>
            <div
              style={{
                textAlign: 'center',

                maxWidth: '345',
                marginRight: 'auto',
                marginLeft: 'auto',
              }}
            >
              <div>
                <H1>Welcome to Lisa’s Story!</H1>
              </div>
              <H2>
                A short video where you’ll learn about prediabetes and some
                things you can do to reverse it. Click play in the video below
                to learn more!{' '}
              </H2>
            </div>
          </div>
        </div>
        <div
          style={{
            width: 'auto',
            backgroundColor: '#F2F8FD',

            marginLeft: 'auto',
            marginRight: 'auto',
            paddingTop: '29px',
            paddingBottom: '33px',
          }}
        >
          {' '}
          <iframe
            width="344"
            height="191"
            src="https://www.youtube.com/embed/azKL5xutMJE"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            aria-label="lisa's story"
          ></iframe>
        </div>
        <div
          style={{
            maxWidth: '1140px',
            backgroundColor: '#FFF',
            marginLeft: 'auto',
            marginRight: 'auto',

            marginBottom: '20px',
          }}
        >
          <div
            style={{
              maxWidth: '344px',
              marginLeft: 'auto',
              marginRight: 'auto',
              paddingTop: '31px',
            }}
          >
            {' '}
            <Text>
              Continue on your Path 2 Prevention below by learning more about
              prediabetes and the National DPP, or move to our Myth vs. Fact
              game where you’ll get to test your knowledge about prediabetes and
              type 2 diabetes.
            </Text>
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <ContinueButton onClick={handleLearnMoreClick}>
                Continue
              </ContinueButton>
            </div>
            <div
              style={{
                paddingTop: '20px',
                textAlign: 'center',
                paddingBottom: '37px',
              }}
            >
              <GreenHyperlink
                href="https://www.cdc.gov/diabetes-prevention/about-prediabetes-type-2/index.html"
                target="_blank"
              >
                Learn More About Prediabetes
              </GreenHyperlink>
            </div>
          </div>
        </div>
      </Responsive>
    </Container>
  );
};
