import React, { useEffect } from 'react';
import { Modal, Image, List } from 'semantic-ui-react';
import FocusTrap from 'focus-trap-react';
import { withStore } from '../../store';
import AddendumContent from './AddendumContent';
import { CloseButton, LogoDiv } from './Styles';

const AddendumModal = (props) => {
  // Add effect to manage body aria-hidden
  useEffect(() => {
    if (props.open) {
      document.getElementById('root').setAttribute('aria-hidden', 'true');
    } else {
      document.getElementById('root').removeAttribute('aria-hidden');
    }

    // Cleanup function to ensure aria-hidden is removed when component unmounts
    return () => {
      document.getElementById('root').removeAttribute('aria-hidden');
    };
  }, [props.open]);

  return (
    <Modal
      size="small"
      open={props.open}
      onClose={props.close}
      closeOnDimmerClick={false}
      style={{
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: '0px !important',
        position: 'static',
      }}
      role="dialog"
      aria-modal="true"
      aria-label="Getting Started Modal"
    >
      <FocusTrap>
        <div>
          <List verticalAlign="middle">
            <List.Item style={{ textAlign: 'center' }}>
              <LogoDiv>
                <Image
                  src="assets/P2P_Small.svg"
                  alt="P2P Logo"
                  style={{
                    height: '27px',
                    width: '36px',
                    marginLeft: '48px',
                  }}
                ></Image>
              </LogoDiv>
              <List.Content style={{ float: 'right' }}>
                <CloseButton
                  size="big"
                  onClick={props.close}
                  title="close"
                  aria-label="close"
                >
                  <Image src="assets/close.svg" alt=""></Image>
                </CloseButton>
              </List.Content>
            </List.Item>
          </List>
          <Modal.Content>
            <AddendumContent close={props.close} />
          </Modal.Content>
        </div>
      </FocusTrap>
    </Modal>
  );
};

export default withStore(AddendumModal);